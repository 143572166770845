import React, { useState, useEffect, useRef } from 'react';
import { useSwipeable } from 'react-swipeable';
import './TransactionBox.css';

const TransactionBox = ({ transactionData, isLoading, searchTrigger }) => {
  const [displayTransactions, setDisplayTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [transactionsPerPage, setTransactionsPerPage] = useState(20); // Default value
  const boxRef = useRef(null);

  useEffect(() => {
    const updateTransactionsPerPage = () => {
      if (boxRef.current) {
        const boxHeight = boxRef.current.clientHeight;
        const paginationHeight = 60; // Adjust based on your pagination container height
        const availableHeight = boxHeight - paginationHeight;
        const estimatedItemHeight = 30; // Adjust based on your transaction item height
        const calculatedTransactionsPerPage = Math.floor(availableHeight / estimatedItemHeight);
        setTransactionsPerPage(Math.max(1, calculatedTransactionsPerPage)); // Ensure at least 1 transaction is shown
      }
    };

    updateTransactionsPerPage();
    window.addEventListener('resize', updateTransactionsPerPage);

    return () => window.removeEventListener('resize', updateTransactionsPerPage);
  }, []);

  useEffect(() => {
    if (transactionData) {
      setDisplayTransactions(transactionData);
      setCurrentPage(1);
    }
  }, [transactionData, searchTrigger]);

  const indexOfLastTransaction = currentPage * transactionsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
  const currentTransactions = displayTransactions.slice(indexOfFirstTransaction, indexOfLastTransaction);

  const handlePageChange = (direction) => {
    if (direction === 'next' && indexOfLastTransaction < displayTransactions.length) {
      setCurrentPage(currentPage + 1);
    } else if (direction === 'prev' && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => handlePageChange('next'),
    onSwipedRight: () => handlePageChange('prev'),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  const getTransactionEmoji = (type) => {
    switch (type) {
      case 'Payment':
        return '💳';
      case 'Invoke':
        return '🪝';
      case 'ClaimReward':
        return '🎁';
      case 'GenesisMint':
        return '💰';
      case 'Remit':
        return '🗳️';
      case 'URITokenBuy':
        return '🎟️';
      case 'TrustSet':
        return '🤝';
      case 'OfferCreate':
        return '📊';
      case 'OfferCancel':
        return '🚫';
      case 'Import':
        return '📥';
      case 'AccountSet':
        return '⚙️';
      case 'URITokenBurn':
        return '🔥';
      default:
        return '❓'; // Question mark for unknown types
    }
  };

  const getFlowTypeEmoji = (flowType) => {
    switch (flowType) {
      case 'in':
        return { emoji: '↩', color: '#32CD32' }; // Green
      case 'out':
        return { emoji: '↪', color: '#FF4500' }; // Red
      case 'inHouse':
        return { emoji: '♲', color: '#808080' }; // Grey
      default:
        return { emoji: '', color: 'white' };
    }
  };

  const getCurrencyColor = (currency) => {
    switch (currency) {
      case 'XAH':
        return 'white';
      case 'EVR':
        return '#00BFFF'; // Deep Sky Blue
      case 'USD':
        return '#32CD32'; // Lime Green
      case 'XRP':
        return '#4169E1'; // Royal Blue
      case 'BTC':
        return '#FFA500'; // Orange
      case 'ETH':
        return '#8A2BE2'; // Blue Violet
      case 'MAG':
        return '#FF1493'; // Deep Pink
      case 'CLV':
        return '#00CED1'; // Dark Turquoise
      default:
        return 'white';
    }
  };

  const renderNoTransactions = () => (
    <div className="no-transactions">
      <img src="/tables_seats/xamini.png" alt="Xamini" className="no-transactions__image" />
      <h2 className="no-transactions__title">Welcome to Xamini</h2>
      <div className="no-transactions__content">
        <p className="no-transactions__text no-transactions__text--intro">
          Explore the Xahau Governance Game with Xamini, your dedicated explorer 🔍:
        </p>
        <p className="no-transactions__text no-transactions__text--1">
          🏛️ 400 stakeholders can manage Xahau through Layer 1 (20 inner circle tables) and Layer 2 (outer tables, each with 20 seats).
        </p>
        <p className="no-transactions__text no-transactions__text--2">
          👆 Click on any table to view the wallet details of its occupant. You can also search for your own Xahau wallet information 🔎.
        </p>
        <p className="no-transactions__text no-transactions__text--3">
          🗳️ Participants vote on seats topics (80% required), Hooks topics (100%), and rewards topics (100%). Layer 2 tables can escalate votes to Layer 1 with 51% majority.
        </p>
        <p className="no-transactions__text no-transactions__text--4">🌟 Key features:</p>
        <ul className="no-transactions__list">
          <li>🔄 Real-time updates on game participants</li>
          <li>📊 Detailed wallet information for each seat occupant</li>
          <li>🔍 Personal Xahau wallet lookup and analysis</li>
          <li>🔘 Click the button in the center 😉</li>
        </ul>
        <p className="no-transactions__text no-transactions__text--outro">
          🚀 Start exploring Xamini and take a deep dive into the Xahau Governance Game!
        </p>
      </div>
    </div>
  );

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="transaction-loading-indicator">
          <div className="transaction-dot"></div>
          <div className="transaction-dot"></div>
          <div className="transaction-dot"></div>
        </div>
      );
    }

    if (displayTransactions.length === 0) {
      return renderNoTransactions();
    }

    const isLastPage = indexOfLastTransaction >= displayTransactions.length;

    return (
      <>
        <div className="transaction-box__list">
          {currentTransactions.map((transaction, index) => (
            <div key={transaction.tx?.hash || index} className={`transaction-box__item ${index % 2 === 0 ? 'even' : 'odd'}`}>
              <span className={`transaction-box__type ${transaction.tx?.TransactionType || ''}`}>
                {transaction.tx?.TransactionType || ''}
              </span>
              <span className="transaction-box__date">{transaction.tx?.FormattedDate || ''}</span>
              <span className="transaction-box__amount">
                {transaction.tx?.FormattedAmount ? (
                  <>
                    <span className="amount-value">
                      {transaction.tx.FormattedAmount.split(' ')[0]}
                    </span>
                    <span 
                      className="amount-currency"
                      style={{ color: getCurrencyColor(transaction.tx.FormattedAmount.split(' ')[1]) }}
                    >
                      {transaction.tx.FormattedAmount.split(' ')[1]}{' '}
                      {getTransactionEmoji(transaction.tx.TransactionType)}{' '}
                    </span>
                    <span style={{ color: getFlowTypeEmoji(transaction.tx.FlowType).color }}>
                      {getFlowTypeEmoji(transaction.tx.FlowType).emoji}
                    </span>
                  </>
                ) : (
                  <>
                    <span className="amount-currency">
                      {getTransactionEmoji(transaction.tx.TransactionType)}{' '}
                      <span style={{ color: getFlowTypeEmoji(transaction.tx.FlowType).color }}>
                        {getFlowTypeEmoji(transaction.tx.FlowType).emoji}
                      </span>
                    </span>
                  </>
                )}
              </span>
            </div>
          ))}
          {isLastPage && (
            <div className={`transaction-box__item info-row ${currentTransactions.length % 2 === 0 ? 'even' : 'odd'}`}>
              Showing up to 200 Transactions
            </div>
          )}
          {/* Add placeholder items for smaller screens */}
          {Array.from({ length: Math.max(0, 15 - currentTransactions.length - (isLastPage ? 1 : 0)) }).map((_, index) => (
            <div key={`placeholder-${index}`} className="transaction-box__item placeholder"></div>
          ))}
        </div>
        <div className="transaction-box__pagination-container">
          <button 
            className="pagination-button" 
            onClick={() => handlePageChange('prev')} 
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span>Page {currentPage}</span>
          <button 
            className="pagination-button" 
            onClick={() => handlePageChange('next')} 
            disabled={indexOfLastTransaction >= displayTransactions.length}
          >
            Next
          </button>
        </div>
      </>
    );
  };

  return (
    <div className="transaction-box" ref={boxRef}>
      {renderContent()}
    </div>
  );
};

export default TransactionBox;
