import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './Header.css';

const Header = ({ onWalletSearch, resetSearch }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [recentSearches, setRecentSearches] = useState([]);
  const [showRecentSearches, setShowRecentSearches] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(prevState => !prevState);
  };

  const handleSearch = (query) => {
    const trimmedQuery = query.trim();
    if (trimmedQuery) {
      if (isValidXRPLAddress(trimmedQuery)) {
        if (location.pathname !== '/governance') {
          // Navigate to governance page with search query
          window.dispatchEvent(new CustomEvent('performSearch', { detail: { searchQuery: trimmedQuery } }));
          navigate('/governance', { state: { searchQuery: trimmedQuery } });
        } else {
          // If already on Governance page, perform the search
          onWalletSearch(trimmedQuery);
        }
        updateRecentSearches(trimmedQuery);
        setSearchQuery(''); // Clear the search input after search
        setErrorMessage(''); // Clear any existing error message
        setShowRecentSearches(false); // Hide recent searches dropdown
      } else {
        setErrorMessage('Invalid XRPL address');
      }
    }
  };

  const handleRecentSearchClick = (query) => {
    setSearchQuery(query);
    handleSearch(query);
  };

  const updateRecentSearches = (query) => {
    setRecentSearches(prevSearches => {
      const updatedSearches = [query, ...prevSearches.filter(s => s !== query)].slice(0, 5);
      localStorage.setItem('recentWalletSearches', JSON.stringify(updatedSearches));
      return updatedSearches;
    });
  };

  useEffect(() => {
    const savedSearches = JSON.parse(localStorage.getItem('recentWalletSearches') || '[]');
    setRecentSearches(savedSearches);
  }, []);

  useEffect(() => {
    if (resetSearch) {
      setSearchQuery('');
    }
  }, [resetSearch]);

  useEffect(() => {
    const menuItems = document.querySelectorAll('.nav-menu ul li a');
    menuItems.forEach(item => {
      item.addEventListener('click', () => {
        setIsMenuOpen(false); // Close the menu
      });
    });

    // Cleanup event listeners on unmount
    return () => {
      menuItems.forEach(item => {
        item.removeEventListener('click', () => {
          setIsMenuOpen(false);
        });
      });
    };
  }, []);

  // Updated function to validate XRPL address
  const isValidXRPLAddress = (address) => {
    // This regex pattern checks for a string that:
    // 1. Starts with 'r'
    // 2. Is followed by 32 or 33 characters
    // 3. Only contains letters and numbers
    const xrplAddressRegex = /^r[1-9A-HJ-NP-Za-km-z]{32,33}$/;
    return xrplAddressRegex.test(address);
  };

  return (
    <header className="header">
      <div className="logo">
        <Link to="/">Xamini</Link>
      </div>
      <form className="search-bar" onSubmit={(e) => { e.preventDefault(); handleSearch(searchQuery); }}>
        <div className="search-input-container">
          <input
            type="text"
            placeholder="Search Xahau wallet..."
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              setErrorMessage(''); // Clear error message when input changes
            }}
            onFocus={() => setShowRecentSearches(true)}
            onBlur={() => setTimeout(() => setShowRecentSearches(false), 200)}
          />
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          {showRecentSearches && recentSearches.length > 0 && (
            <div className="recent-searches-dropdown">
              {recentSearches.map((search, index) => (
                <div 
                  key={index} 
                  className="recent-search-item"
                  onClick={() => handleRecentSearchClick(search)}
                >
                  {search}
                </div>
              ))}
            </div>
          )}
        </div>
      </form>
      <div className={`hamburger ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <nav className={`nav-menu ${isMenuOpen ? 'open' : ''}`}>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/governance">Governance</Link></li>
          <li><Link to="/rules-of-engagement">Rules of Engagement</Link></li>
          <li><Link to="/ecosystem">Ecosystem</Link></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
