import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import StarryBackground from './components/StarryBackground';
import Header from './components/Header';
import SmartContractPage from './components/SmartContractPage';
import ContractDetails from './components/ContractDetails';
import Home from './components/Home';
import GenericPage from './components/GenericPage';
import Footer from './components/Footer';
import Validators from './components/Validators';
import Ecosystem from './components/Ecosystem';
import Governance from './components/Governance';
import Events from './components/Events';
import HooksPage from './components/HooksPage';
import RulesOfEngagement from './components/RulesOfEngagement';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HookStore from './components/HookStore';
import Dashboard from './pages/Dashboard';
import Collections from './pages/Collections';
import CollectionPage from './pages/CollectionPage';
import NFTDetail from './pages/NFTDetail';
import CommunityGrants from './pages/CommunityGrants';
import GrantDetail from './pages/GrantDetail';
import Layout from './components/Layout';
import MultiDex from './components/MultiDex';
import Demo from './pages/Demo';
// import Globe from './components/Globe';



const pageContent = {
  staking: {
    title: "Staking",
    content: "This is the staking page. Here you can stake your tokens and earn rewards."
  },
  governance: {
    title: "Governance",
    content: "This is the governance page. Here you can participate in protocol decisions and vote on proposals."
  }
};

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const [searchQuery, setSearchQuery] = useState('');
  const [resetSearch, setResetSearch] = useState(false);
  const [userWalletAddress, setUserWalletAddress] = useState('');

  // Comment out the useEffect for logging state changes
  /*
  useEffect(() => {
    console.log('searchQuery:', searchQuery);
    console.log('resetSearch:', resetSearch);
  }, [searchQuery, resetSearch]);
  */

  const handleWalletSearch = (query) => {
    // console.log('handleWalletSearch called with query:', query);
    setSearchQuery(query);
    setResetSearch(false);
    setUserWalletAddress(query);
  };

  const handleSearchComplete = () => {
    // console.log('handleSearchComplete called');
    setResetSearch(true);
  };

  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <StarryBackground />
        <Header onWalletSearch={handleWalletSearch} resetSearch={resetSearch} />
        <main className="main-content">
          <Routes>
            <Route element={<Layout />}>
              <Route path="/" element={<Home />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/governance" element={
                <Governance 
                  searchQuery={searchQuery} 
                  onSearchComplete={handleSearchComplete}
                />
              } />
              <Route path="/ecosystem" element={<Ecosystem />} />
              <Route path="/rules-of-engagement" element={<RulesOfEngagement />} />
              <Route path="/collections" element={<Collections />} />
              <Route path="/collections/:id" element={<CollectionPage />} />
              <Route path="/nft/:id" element={<NFTDetail userWalletAddress={userWalletAddress} />} />
              <Route path="/community-grants" element={<CommunityGrants />} />
              <Route path="/grants/:id" element={<GrantDetail />} />
              <Route path="/multi-dex" element={<MultiDex />} />
              <Route path="/events" element={<Events />} />
              <Route path="/demo" element={<Demo />} />
              {/* Disabled XRP Meetups route
              <Route path="/xrp-meetups" element={
                <div style={{ width: '100vw', height: '100vh', position: 'relative' }}>
                  <Globe />
                </div>
              } />
              */}
            </Route>
          </Routes>
        </main>
        <Footer />
        <ToastContainer />
      </div>
    </Router>
  );
}

export default App;
