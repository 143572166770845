import React, { useState, useEffect, useMemo } from 'react';
import './ImageGallery.css';

const originalNfts = [
  { id: 1, name: 'ANXELS', artist: 'ANXELS', price: '0.5 XAH', x: '@anXelsNFT', src: '/images/anxels.png' },
  { id: 2, name: 'Azzerpardis', artist: 'Chef Tony', price: '0.7 XAH', x: '@AzzerpardisXRPL', src: '/images/azzerpardis.png' },
  { id: 3, name: 'Beast And Belles', artist: 'Dave Suppnick', price: '0.3 ETH', x: '@GoGuerrillaX', src: '/images/beast _belles.png' },
  { id: 4, name: 'Goat Club', artist: 'Yona', price: '0.8 XAH', x: '@yoniartnft',  src: '/images/goat_club.png' },
  { id: 5, name: 'Hook Bots', artist: 'Shellfish', price: '0.6 XAH', x: '@XRPeaceOfMind', src: '/images/hook_bots.png' },
  { id: 6, name: 'Living Legacy', artist: 'Living Legacy', price: '0.4 XAH', x: '@livinglegacy15', src: '/images/living_legacy.png' },
  { id: 7, name: 'Monks', artist: 'Token Panda', price: '0.9 XAH', x: '@token_panda', src: '/images/monks.png' },
  { id: 8, name: 'The Space is Now', artist: 'Crypto Queen', price: '0.5 XAH', x: '@CryptoQueen_555', src: '/images/now_is_the_space.png' },
  { id: 9, name: 'Winnie', artist: 'Steamboat Willie', price: '0.7 XAH', x: '@WillieXRPL', src: '/images/winnie.png' },
  { id: 10, name: 'Xahau Cows', artist: 'Goodiebags', price: '0.6 XAH', x: '@Goodebags', src: '/images/xahau_cows.png' },
  { id: 11, name: 'Xahau Eggs', artist: 'Cbot', price: '0.8 XAH', x: '@Cbot_Xrpl', src: '/images/xahau_eggs.png' },
  { id: 12, name: 'xSOLDIERZ', artist: 'xSOLDIERZ', price: '0.5 XAH', x: '@xSOLDIERZNFT', src: '/images/xSoldiers.png' },
];

const ImageGallery = () => {
  const [selectedNFT, setSelectedNFT] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Shuffle the nfts array
  const shuffledNfts = useMemo(() => {
    return [...originalNfts].sort(() => Math.random() - 0.5);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleImageClick = (nft) => {
    setSelectedNFT(nft);
    document.body.style.overflow = 'hidden';
  };

  const handleCloseModal = () => {
    setSelectedNFT(null);
    document.body.style.overflow = 'auto';
  };

  const handleBuy = (nft) => {
    alert(`You're about to purchase ${nft.name} for ${nft.price}`);
    // Implement actual purchase logic here
  };

  const scrollGallery = (direction) => {
    const gallery = document.querySelector('.image-gallery');
    const scrollAmount = gallery.clientWidth / 2;
    gallery.scrollBy({
      left: direction === 'left' ? -scrollAmount : scrollAmount,
      behavior: 'smooth'
    });
  };

  return (
    <div className="image-gallery-wrapper">
      <div className="image-gallery-container">
        <button className="scroll-arrow left-arrow" onClick={() => scrollGallery('left')}>&lt;</button>
        <div className="image-gallery">
          {shuffledNfts.map((nft) => (
            <div key={nft.id} className="nft-container">
              <div className="nft-image-wrapper">
                <img
                  src={nft.src}
                  alt={nft.name}
                  onClick={() => handleImageClick(nft)}
                />
              </div>
              <div className="nft-info">
                <p>By {nft.artist}</p>
                <p className="price">{nft.price}</p>
                <button onClick={() => handleBuy(nft)}>Buy Now</button>
              </div>
            </div>
          ))}
        </div>
        <button className="scroll-arrow right-arrow" onClick={() => scrollGallery('right')}>&gt;</button>
      </div>
      {selectedNFT && (
        <div className="modal" onClick={handleCloseModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={handleCloseModal}>&times;</button>
            <div className="modal-image-container">
              <img className="modal-image" src={selectedNFT.src} alt={selectedNFT.name} />
            </div>
            <div className="modal-info">
              <h2>{selectedNFT.name}</h2>
              <p>Artist: {selectedNFT.artist}</p>
              <p className="price">Price: {selectedNFT.price}</p>
              <button onClick={() => handleBuy(selectedNFT)}>Buy Now</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageGallery;