import React, { useState } from 'react';
import './HookStore.css';

const AVAILABLE_HOOKS = [
  {
    id: 1,
    name: 'Auto-Savings Hook',
    description: 'Automatically saves a percentage of every incoming transaction to a designated savings wallet.',
    parameters: [
      {
        type: 'slider',
        name: 'savingsRate',
        label: 'Savings Rate',
        min: 0,
        max: 50,
        unit: '%'
      },
      {
        type: 'number',
        name: 'threshold',
        label: 'Minimum Transaction Threshold',
        min: 0,
        unit: 'USD'
      },
      {
        type: 'dropdown',
        name: 'savingsWallet',
        label: 'Savings Wallet',
        options: [] // Will be populated with user's wallets
      }
    ]
  },
  {
    id: 2,
    name: 'Auto-Contribution for Carbon Offset',
    description: 'Contribute a fixed or percentage-based fee from each transaction to a carbon offset program.',
    parameters: [
      {
        type: 'slider',
        name: 'contributionAmount',
        label: 'Contribution Amount',
        min: 0,
        max: 10,
        unit: 'USD'
      },
      {
        type: 'toggle',
        name: 'isPercentageBased',
        label: 'Use Percentage-Based Contribution'
      },
      {
        type: 'dropdown',
        name: 'recipient',
        label: 'Environmental Organization',
        options: [
          { value: 'org1', label: 'Carbon Offset Fund A' },
          { value: 'org2', label: 'Green Initiative B' },
          { value: 'org3', label: 'Environmental Trust C' }
        ]
      }
    ]
  },
  {
    id: 3,
    name: 'Payment Splitting Hook',
    description: 'Automatically split income from any incoming transaction to multiple wallets.',
    parameters: [
      {
        type: 'slider',
        name: 'splitRatioA',
        label: 'Wallet A Ratio',
        min: 0,
        max: 100,
        unit: '%'
      },
      {
        type: 'slider',
        name: 'splitRatioB',
        label: 'Wallet B Ratio',
        min: 0,
        max: 100,
        unit: '%'
      },
      {
        type: 'dropdown',
        name: 'walletA',
        label: 'Wallet A Selection',
        options: [] // Will be populated with user's wallets
      },
      {
        type: 'dropdown',
        name: 'walletB',
        label: 'Wallet B Selection',
        options: [] // Will be populated with user's wallets
      },
      {
        type: 'dropdown',
        name: 'transactionType',
        label: 'Transaction Types',
        options: [
          { value: 'all', label: 'All Transactions' },
          { value: 'payments', label: 'Payments Only' },
          { value: 'trades', label: 'Trades Only' }
        ]
      }
    ]
  },
  {
    id: 4,
    name: 'Recurring Payment Hook',
    description: 'Enables automatic recurring payments to designated wallets at set intervals.',
    parameters: [
      {
        type: 'number',
        name: 'paymentAmount',
        label: 'Payment Amount',
        min: 0,
        unit: 'USD'
      },
      {
        type: 'dropdown',
        name: 'frequency',
        label: 'Payment Frequency',
        options: [
          { value: 'daily', label: 'Daily' },
          { value: 'weekly', label: 'Weekly' },
          { value: 'monthly', label: 'Monthly' }
        ]
      },
      {
        type: 'dropdown',
        name: 'recipientWallet',
        label: 'Recipient Wallet',
        options: [] // Will be populated with user's wallets
      }
    ]
  },
  {
    id: 5,
    name: 'Spending Limit Hook',
    description: 'Sets a daily or weekly spending cap for the user\'s account, preventing excess spending.',
    parameters: [
      {
        type: 'slider',
        name: 'spendingLimit',
        label: 'Spending Limit',
        min: 0,
        max: 1000,
        unit: 'USD'
      },
      {
        type: 'dropdown',
        name: 'resetFrequency',
        label: 'Reset Frequency',
        options: [
          { value: 'daily', label: 'Daily' },
          { value: 'weekly', label: 'Weekly' }
        ]
      },
      {
        type: 'toggle',
        name: 'notifications',
        label: 'Enable Notifications'
      }
    ]
  },
  {
    id: 6,
    name: 'Automatic Investment Hook',
    description: 'Automatically invests a percentage of incoming payments into a specified token or asset.',
    parameters: [
      {
        type: 'slider',
        name: 'investmentRate',
        label: 'Investment Rate',
        min: 0,
        max: 20,
        unit: '%'
      },
      {
        type: 'dropdown',
        name: 'asset',
        label: 'Asset Selection',
        options: [
          { value: 'usdc', label: 'USDC' },
          { value: 'usdt', label: 'USDT' },
          { value: 'btc', label: 'BTC' }
        ]
      },
      {
        type: 'number',
        name: 'threshold',
        label: 'Investment Threshold',
        min: 0,
        unit: 'USD'
      }
    ]
  },
  {
    id: 7,
    name: 'Tax Withholding Hook',
    description: 'Automatically withholds a set percentage of income for tax purposes.',
    parameters: [
      {
        type: 'slider',
        name: 'taxRate',
        label: 'Tax Rate',
        min: 0,
        max: 30,
        unit: '%'
      },
      {
        type: 'number',
        name: 'threshold',
        label: 'Minimum Transaction Amount',
        min: 0,
        unit: 'USD'
      },
      {
        type: 'dropdown',
        name: 'taxWallet',
        label: 'Tax Wallet',
        options: [] // Will be populated with user's wallets
      }
    ]
  },
  {
    id: 8,
    name: 'Emergency Freeze Hook',
    description: 'Automatically restricts outgoing transactions if unusual activity is detected.',
    parameters: [
      {
        type: 'slider',
        name: 'triggerAmount',
        label: 'Trigger Amount',
        min: 0,
        max: 1000,
        unit: 'USD'
      },
      {
        type: 'dropdown',
        name: 'duration',
        label: 'Freeze Duration',
        options: [
          { value: '24', label: '24 Hours' },
          { value: '48', label: '48 Hours' },
          { value: '72', label: '72 Hours' }
        ]
      },
      {
        type: 'toggle',
        name: 'notifications',
        label: 'Enable Notifications'
      }
    ]
  }
];

const HookStore = () => {
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [installationStep, setInstallationStep] = useState('none'); // none, select, configure
  const [selectedHook, setSelectedHook] = useState(null);
  const [hookParameters, setHookParameters] = useState({});

  const handleSlotClick = (index) => {
    setSelectedSlot(index);
    setInstallationStep('select');
  };

  const handleHookSelect = (hook) => {
    setSelectedHook(hook);
    setInstallationStep('configure');
    const defaultParams = {};
    hook.parameters.forEach(param => {
      defaultParams[param.name] = param.type === 'slider' ? param.min : '';
    });
    setHookParameters(defaultParams);
  };

  const handleParameterChange = (paramName, value) => {
    setHookParameters(prev => ({
      ...prev,
      [paramName]: value
    }));
  };

  const handleInstallHook = () => {
    console.log('Installing hook:', selectedHook, 'with parameters:', hookParameters);
    setInstallationStep('none');
    setSelectedSlot(null);
  };

  const handleBack = () => {
    if (installationStep === 'configure') {
      setInstallationStep('select');
      setSelectedHook(null);
    } else if (installationStep === 'select') {
      setInstallationStep('none');
      setSelectedSlot(null);
    }
  };

  const renderParameterInput = (param) => {
    switch (param.type) {
      case 'slider':
        return (
          <div className="parameter-input">
            <label>{param.label}</label>
            <input
              type="range"
              min={param.min}
              max={param.max}
              value={hookParameters[param.name] || param.min}
              onChange={(e) => handleParameterChange(param.name, e.target.value)}
            />
            <span>{hookParameters[param.name] || param.min}{param.unit}</span>
          </div>
        );
      case 'number':
        return (
          <div className="parameter-input">
            <label>{param.label}</label>
            <input
              type="number"
              min={param.min}
              value={hookParameters[param.name] || ''}
              onChange={(e) => handleParameterChange(param.name, e.target.value)}
            />
            <span>{param.unit}</span>
          </div>
        );
      case 'dropdown':
        return (
          <div className="parameter-input">
            <label>{param.label}</label>
            <select
              value={hookParameters[param.name] || ''}
              onChange={(e) => handleParameterChange(param.name, e.target.value)}
            >
              <option value="">Select a wallet</option>
              {param.options.map(opt => (
                <option key={opt.value} value={opt.value}>{opt.label}</option>
              ))}
            </select>
          </div>
        );
      case 'toggle':
        return (
          <div className="parameter-input">
            <label className="toggle-label">
              <input
                type="checkbox"
                checked={hookParameters[param.name] || false}
                onChange={(e) => handleParameterChange(param.name, e.target.checked)}
              />
              {param.label}
            </label>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="hook-store-container">
      <h1>Hook Store</h1>
      
      {installationStep === 'none' && (
        <div className="hook-slots-grid">
          {[...Array(10)].map((_, index) => (
            <div 
              key={index} 
              className="hook-slot"
              onClick={() => handleSlotClick(index)}
            >
              <div className="hook-slot-content">
                <div className="hook-slot-empty">
                  <span>Empty Hook Slot</span>
                  <button className="install-button">Install Hook</button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {installationStep === 'select' && (
        <div className="hook-selection">
          <div className="section-header">
            <button className="back-button" onClick={handleBack}>
              ← Back
            </button>
            <h2>Select a Hook to Install</h2>
          </div>
          <div className="hooks-list">
            {AVAILABLE_HOOKS.map(hook => (
              <div 
                key={hook.id} 
                className="hook-option"
                onClick={() => handleHookSelect(hook)}
              >
                <h3>{hook.name}</h3>
                <p>{hook.description}</p>
              </div>
            ))}
          </div>
        </div>
      )}

      {installationStep === 'configure' && selectedHook && (
        <div className="hook-configuration">
          <div className="section-header">
            <button className="back-button" onClick={handleBack}>
              ← Back
            </button>
            <h2>Configure {selectedHook.name}</h2>
          </div>
          <div className="parameters-form">
            {selectedHook.parameters.map(param => (
              <div key={param.name}>
                {renderParameterInput(param)}
              </div>
            ))}
            <button 
              className="install-button"
              onClick={handleInstallHook}
            >
              Install Hook
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default HookStore; 