import React, { useState, useEffect } from 'react';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { languageExamples } from '../languageExamples';

// Import language support
import javascript from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';
import python from 'react-syntax-highlighter/dist/esm/languages/hljs/python';
import java from 'react-syntax-highlighter/dist/esm/languages/hljs/java';
import cpp from 'react-syntax-highlighter/dist/esm/languages/hljs/cpp';
import rust from 'react-syntax-highlighter/dist/esm/languages/hljs/rust';
import php from 'react-syntax-highlighter/dist/esm/languages/hljs/php';
import xml from 'react-syntax-highlighter/dist/esm/languages/hljs/xml';
import css from 'react-syntax-highlighter/dist/esm/languages/hljs/css';

// Register languages
SyntaxHighlighter.registerLanguage('javascript', javascript);
SyntaxHighlighter.registerLanguage('python', python);
SyntaxHighlighter.registerLanguage('java', java);
SyntaxHighlighter.registerLanguage('cpp', cpp);
SyntaxHighlighter.registerLanguage('rust', rust);
SyntaxHighlighter.registerLanguage('php', php);
SyntaxHighlighter.registerLanguage('html', xml);
SyntaxHighlighter.registerLanguage('css', css);

const CodeGenerator = () => {
  const [userInput, setUserInput] = useState('');
  const [generatedCode, setGeneratedCode] = useState('');
  const [language, setLanguage] = useState('');

  useEffect(() => {
    const lowercaseInput = userInput.toLowerCase().trim();
    if (languageExamples[lowercaseInput]) {
      setGeneratedCode(languageExamples[lowercaseInput]);
      setLanguage(lowercaseInput);
    } else {
      setGeneratedCode('');
      setLanguage('');
    }
  }, [userInput]);

  return (
    <div className="code-generator">
      {generatedCode && (
        <div className="code-display">
          <SyntaxHighlighter
            language={language}
            style={atomOneDark}
            showLineNumbers={true}
            wrapLines={true}
            customStyle={{
              background: 'transparent',
              padding: '20px',
              fontSize: '14px',
            }}
          >
            {generatedCode}
          </SyntaxHighlighter>
        </div>
      )}
      <div className="input-container">
        <textarea
          className="auto-resize-textarea"
          placeholder="Enter a programming language (e.g., javascript, python)"
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
        />
      </div>
    </div>
  );
};

export default CodeGenerator;