// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rules-container {
    color: white;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    line-height: 1.6;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.rules-title {
    color: #61dafb;
    font-weight: 600;
    text-align: center;
    margin-bottom: 30px;
}

.rules-container strong {
    color: #61dafb;
    display: block;
    font-size: 1.2em;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 10px;
}

.rules-container p, .rules-container ul {
    margin-bottom: 15px;
}

.rules-container ul {
    padding-left: 20px;
}

.rules-container a {
    color: #61dafb;
    text-decoration: none;
}

.rules-container a:hover {
    text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/components/RulesOfEngagement.css"],"names":[],"mappings":"AAEA;IACI,YAAY;IACZ,kCAAkC;IAClC,gBAAgB;IAChB,gBAAgB;IAChB,aAAa;IACb,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,cAAc;IACd,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');\n\n.rules-container {\n    color: white;\n    font-family: 'Poppins', sans-serif;\n    font-weight: 300;\n    line-height: 1.6;\n    padding: 20px;\n    max-width: 800px;\n    margin: 0 auto;\n}\n\n.rules-title {\n    color: #61dafb;\n    font-weight: 600;\n    text-align: center;\n    margin-bottom: 30px;\n}\n\n.rules-container strong {\n    color: #61dafb;\n    display: block;\n    font-size: 1.2em;\n    font-weight: 600;\n    margin-top: 20px;\n    margin-bottom: 10px;\n}\n\n.rules-container p, .rules-container ul {\n    margin-bottom: 15px;\n}\n\n.rules-container ul {\n    padding-left: 20px;\n}\n\n.rules-container a {\n    color: #61dafb;\n    text-decoration: none;\n}\n\n.rules-container a:hover {\n    text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
