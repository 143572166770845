import React from 'react';

const HooksPage = () => {
  return (
    <div className="hooks-page">
      <div className="hooks-header">
        <h1 className="title">Hooks Page</h1> {/* Added class for styling */}
      </div>
      <div className="content-container">
        <div className="svg-container">
          {/* Reference the SVG directly from the public folder */}
          <img src="/hook_bot.svg" alt="Hook Bot Illustration" className="hook-svg" />
        </div>
      </div>
    </div>
  );
};

export default HooksPage;
