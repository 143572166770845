import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import './Governance.css';
import WalletView from './WalletView';
import TransactionBox from './TransactionBox';
import WalletStats from './WalletStats';
import Layer1Tables from './Layer1Tables';
import { layer1SeatLogos, layer2SeatLogos, getDefaultLogo } from './SeatLogos';
import { fetchGovernanceData } from '../governanceApi';
import { fetchAccountDetails } from '../walletDetailsAPI';

const GOVERNANCE_DATA_KEY = 'governanceData';
const LAST_FETCH_TIME_KEY = 'governanceLastFetchTime';
const FETCH_INTERVAL = 10 * 60 * 1000; // 10 minutes in milliseconds

const Governance = ({ searchQuery: propSearchQuery, onSearchComplete }) => {
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState('');

  // State variables
  const [expandedSeat, setExpandedSeat] = useState(null);
  const [expandedInnerSeat, setExpandedInnerSeat] = useState(null);
  const [rewardRate, setRewardRate] = useState(null);
  const [scalingFactor, setScalingFactor] = useState(1);
  const [activeWallet, setActiveWallet] = useState(null);
  const [expandedTable, setExpandedTable] = useState(null);
  const [ledgerIndex, setLedgerIndex] = useState(0);
  const [activeLogo, setActiveLogo] = useState('/tables_seats/xahau.png');
  const [showAllColored, setShowAllColored] = useState(false); // Set to false by default
  const [activeSeatLogo, setActiveSeatLogo] = useState(null);
  const [activeInnerSeatLogo, setActiveInnerSeatLogo] = useState(null);
  const [expandedTableName, setExpandedTableName] = useState('Pick a Table or Seat');
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedAccountId, setSelectedAccountId] = useState('--');
  const [selectedAccountBalance, setSelectedAccountBalance] = useState('--');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTokens, setSelectedTokens] = useState([]);
  const [isInteractionAllowed, setIsInteractionAllowed] = useState(true);
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [walletData, setWalletData] = useState(null);
  const [searchedWallet, setSearchedWallet] = useState(null);
  const [isSearchedWallet, setIsSearchedWallet] = useState(false);

  // New state to store governance data
  const [governanceData, setGovernanceData] = useState(null);

  // Constants for seat counts
  const layer1Seats = 20;
  const innerSeats = 20;

  const [searchTrigger, setSearchTrigger] = useState(0);

  // useEffect for state changes (logs removed)
  useEffect(() => {
    // State change logic (if any) can go here
  }, [
    searchQuery, expandedSeat, expandedInnerSeat, rewardRate, activeWallet,
    expandedTable, ledgerIndex, showAllColored, expandedTableName,
    selectedItem, selectedAccountId, selectedAccountBalance, isLoading,
    isInteractionAllowed, searchedWallet, isSearchedWallet, searchTrigger
  ]);

  const handleWalletClick = (event, layer, index, walletAddress) => {
    event.stopPropagation();
    setActiveWallet(prevWallet => {
      if (prevWallet && prevWallet.layer === layer && prevWallet.index === index) {
        return null; // This will hide the wallet view and show the original content
      }
      return { layer, index, address: walletAddress };
    });
  };

  // Function to rotate an array by a given number of positions
  const rotateArray = (arr, positions) => {
    const pos = positions % arr.length;
    return [...arr.slice(pos), ...arr.slice(0, pos)];
  };

  // Function to generate positions for circles
  const generateCirclePositions = (count, radius, innerCircleRadius = 15, scaleFactor = 1) => {
    const adjustedRadius = (radius + innerCircleRadius) * scaleFactor;
    return Array.from({ length: count }, (_, i) => {
      const angle = (i * 2 * Math.PI) / count;
      return {
        x: Math.cos(angle) * adjustedRadius,
        y: Math.sin(angle) * adjustedRadius
      };
    });
  };

  // Memoized calculation of rotated Layer 1 positions
  const rotatedLayer1Positions = useMemo(() => {
    const positions = generateCirclePositions(layer1Seats, 200);
    return rotateArray(positions, 15);
  }, [layer1Seats]);

  // Memoized calculation of rotated inner positions with scale factor
  const rotatedInnerPositions = useMemo(() => {
    const basePositions = generateCirclePositions(innerSeats, 24, 5);
    const expandedPositions = generateCirclePositions(innerSeats, 20.2, 5, 1.2); // 20% increase in radius
    return {
      base: rotateArray(basePositions, 15),
      expanded: rotateArray(expandedPositions, 15)
    };
  }, [innerSeats]);

  const clearWalletData = () => {
    setWalletData(null);
    setSelectedAccountId('--');
    setSelectedAccountBalance('--');
    setSelectedTokens([]);
    setSelectedTransactions([]);
  };

  const clearSearchedWalletData = () => {
    if (isSearchedWallet) {
      setWalletData(null);
      setSelectedAccountId('--');
      setSelectedAccountBalance('--');
      setSelectedTokens([]);
      setSelectedTransactions([]);
      setIsSearchedWallet(false);
    }
  };

  const handleTableClick = (type, index, event) => {
    setSearchedWallet(null);  // Clear the searched wallet
    clearSearchedWalletData();
    if (!isInteractionAllowed) return;
    event.stopPropagation();
    if (expandedSeat !== null) {
      setExpandedSeat(null);
      setExpandedInnerSeat(null);
    }
    setExpandedTable(prevExpandedTable => {
      if (prevExpandedTable === index) {
        setActiveLogo('/tables_seats/xahau.png');
        setExpandedTableName('Pick a Table or Seat');
        setSelectedItem(null);
        return null;
      } else {
        setSelectedItem(`table-${index}`);
        return index;
      }
    });
    setActiveWallet(null);
    clearWalletData();
    if (governanceData && governanceData.layer1Tables && governanceData.layer1Tables[index]) {
      const accountId = governanceData.layer1Tables[index].accountId;
      if (accountId) {
        setIsLoading(true);
        fetchAccountDetails(accountId)
          .then(result => {
            if (result) {
              setSelectedAccountId(result.address);
              setSelectedAccountBalance(result.balance);
              setSelectedTokens(result.tokens || []);
              setSelectedTransactions(result.transactions || []);
            }
          })
          .catch(error => {
            console.error('Error fetching account details:', error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  };

  const fetchAccountData = async (accountId) => {
    setIsLoading(true);
    setIsInteractionAllowed(false);
    setSelectedAccountId('Loading...');
    setSelectedAccountBalance('Loading...');
    setSelectedTokens([]);
    setSelectedTransactions([]);

    try {
      const accountDetails = await fetchAccountDetails(accountId);
      // console.log('Account Details:', accountDetails);
      setSelectedAccountId(accountId);
      setSelectedAccountBalance(accountDetails.balance);
      setSelectedTokens(accountDetails.tokens || []);
      setSelectedTransactions(accountDetails.transactions || []);
      setWalletData({ ...accountDetails, ledgerObjects: accountDetails.ledgerObjects || [] });
    } catch (error) {
      // console.error('Error fetching account details:', error);
      setSelectedAccountId('--');
      setSelectedAccountBalance('--');
      setSelectedTokens([]);
      setSelectedTransactions([]);
      setWalletData(null);
    } finally {
      setIsLoading(false);
      setIsInteractionAllowed(true);
    }
  };

  const handleSeatClick = (layer, seatIndex, event) => {
    setSearchedWallet(null);  // Clear the searched wallet
    clearSearchedWalletData();
    if (!isInteractionAllowed) return;
    event.stopPropagation();
    if (layer === 'outer') {
      setExpandedSeat(prevExpandedSeat => {
        if (prevExpandedSeat === seatIndex) {
          // Collapsing the outer seat
          setExpandedInnerSeat(null);
          setActiveLogo('/tables_seats/xahau.png');
          setExpandedTableName('Pick a Table or Seat');
          setSelectedItem(null);
          setSelectedAccountId('--');
          setSelectedAccountBalance('--');
          setSelectedTransactions([]); // Clear transactions
          return null;
        } else {
          // Expanding a new outer seat
          setExpandedInnerSeat(null);
          setExpandedTable(null); // Collapse any expanded table from Layer1Tables
          const logoInfo = layer1SeatLogos[seatIndex] || { logo: getDefaultLogo(), name: 'Vacant Table' };
          setActiveLogo(logoInfo.logo);
          setExpandedTableName(logoInfo.name);
          setSelectedItem(`table-${seatIndex}`);
          
          if (governanceData && governanceData.layer2Tables && governanceData.layer2Tables[seatIndex]) {
            const accountId = governanceData.layer2Tables[seatIndex].accountId;
            if (accountId) {
              fetchAccountData(accountId);
            } else {
              // Vacant seat
              setSelectedAccountId('--');
              setSelectedAccountBalance('--');
            }
          } else {
            // Vacant seat
            setSelectedAccountId('--');
            setSelectedAccountBalance('--');
          }
          return seatIndex;
        }
      });
    }
    clearWalletData();
  };

  const handleInnerSeatClick = (outerIndex, innerIndex, event) => {
    if (!isInteractionAllowed) return;
    event.stopPropagation();
    if (expandedSeat === outerIndex) {
      setExpandedInnerSeat(prevExpandedInnerSeat => {
        if (prevExpandedInnerSeat === innerIndex) {
          // Collapsing the inner seat, show outer seat info
          const outerLogoInfo = layer1SeatLogos[outerIndex] || { logo: getDefaultLogo(), name: 'Vacant Table' };
          setActiveLogo(outerLogoInfo.logo);
          setExpandedTableName(outerLogoInfo.name);
          // Show outer seat account info
          if (governanceData && governanceData.layer2Tables && governanceData.layer2Tables[outerIndex]) {
            const accountId = governanceData.layer2Tables[outerIndex].accountId;
            if (accountId) {
              fetchAccountData(accountId);
            } else {
              setSelectedAccountId('--');
              setSelectedAccountBalance('--');
              setSelectedTransactions([]); // Clear transactions
            }
          }
          return null;
        } else {
          // Expanding a new inner seat
          setExpandedTable(null); // Collapse any expanded table from Layer1Tables
          const logoInfo = layer2SeatLogos[`${outerIndex}-${innerIndex}`] || { logo: getDefaultLogo(), name: 'Vacant Seat' };
          setActiveLogo(logoInfo.logo);
          setExpandedTableName(logoInfo.name);
          if (governanceData && governanceData.layer2Seats && 
              governanceData.layer2Seats[outerIndex] && 
              governanceData.layer2Seats[outerIndex].seats && 
              governanceData.layer2Seats[outerIndex].seats[innerIndex]) {
            const accountId = governanceData.layer2Seats[outerIndex].seats[innerIndex].accountId;
            if (accountId) {
              fetchAccountData(accountId);
            } else {
              setSelectedAccountId('--');
              setSelectedAccountBalance('--');
            }
          } else {
            setSelectedAccountId('--');
            setSelectedAccountBalance('--');
          }
          return innerIndex;
        }
      });
    }
    clearWalletData();
  };

  const getGovernanceData = useCallback(async () => {
    const now = Date.now();
    const lastFetchTime = localStorage.getItem(LAST_FETCH_TIME_KEY);
    const storedData = localStorage.getItem(GOVERNANCE_DATA_KEY);

    if (storedData && lastFetchTime && now - parseInt(lastFetchTime) < FETCH_INTERVAL) {
      return JSON.parse(storedData);
    }

    try {
      const newData = await fetchGovernanceData();
      localStorage.setItem(GOVERNANCE_DATA_KEY, JSON.stringify(newData));
      localStorage.setItem(LAST_FETCH_TIME_KEY, now.toString());
      return newData;
    } catch (error) {
      console.error('Error fetching governance data:', error);
      return JSON.parse(storedData) || null;
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getGovernanceData();
      if (data) {
        setGovernanceData(data);
        setLedgerIndex(data.ledgerIndex);
        setRewardRate(data.rewardRate);
      }
    };

    fetchData();

    const intervalId = setInterval(fetchData, FETCH_INTERVAL);

    return () => clearInterval(intervalId);
  }, [getGovernanceData]);

  const handleToggleClick = (e) => {
    e.stopPropagation();
    setShowAllColored(prevState => !prevState);
  };

  const shouldColorInnerSeat = (outerIndex, innerIndex) => {
    const seatInfo = layer2SeatLogos[`${outerIndex}-${innerIndex}`];
    return seatInfo && (seatInfo.logo || seatInfo.name);
  };

  const handleSeatWalletClick = (event, layer, index, walletAddress) => {
    event.stopPropagation();
    setActiveWallet(prevWallet => {
      if (prevWallet && prevWallet.layer === layer && prevWallet.index === index) {
        if (layer === 'outer') {
          setActiveSeatLogo(null);
        } else if (layer === 'inner') {
          setActiveInnerSeatLogo(null);
        }
        return null;
      }
      if (layer === 'outer') {
        setActiveSeatLogo(layer1SeatLogos[index] || getDefaultLogo());
      } else if (layer === 'inner') {
        const [outerIndex, innerIndex] = index.split('-');
        setActiveInnerSeatLogo(layer2SeatLogos[`${outerIndex}-${innerIndex}`] || getDefaultLogo());
      }
      return { layer, index, address: walletAddress };
    });
  };

  const getSeatLogoInfo = (layer, index) => {
    if (layer === 'outer') {
      return layer1SeatLogos[index] || { logo: null, name: 'Vacant Table' };
    } else if (layer === 'inner') {
      return layer2SeatLogos[index] || { logo: null, name: 'Vacant Seat' };
    }
    return { logo: null, name: 'Unknown Seat' };
  };

  const getSeatColor = (layer, outerIndex, innerIndex = null) => {
    if (showAllColored) {
      return 'colored';
    }
    if (layer === 'table') {
      return governanceData && governanceData.layer1Tables && governanceData.layer1Tables[outerIndex]?.accountId
        ? 'colored'
        : 'grey';
    } else if (layer === 'outer') {
      return governanceData && governanceData.layer2Tables && governanceData.layer2Tables[outerIndex]?.accountId
        ? 'colored'
        : 'grey';
    } else if (layer === 'inner') {
      return governanceData && governanceData.layer2Seats && 
             governanceData.layer2Seats[outerIndex]?.seats[innerIndex]?.accountId
        ? 'colored'
        : 'grey';
    }
    return 'grey';
  };

  useEffect(() => {
    const handleSearch = async (event) => {
      if (event.detail && event.detail.searchQuery) {
        setSearchQuery(event.detail.searchQuery);
        setSearchTrigger(prev => prev + 1);
        
        // Use the stored governance data for the search
        const storedGovernanceData = await getGovernanceData();
        // Use storedGovernanceData for any search-related operations
      }
    };

    window.addEventListener('performSearch', handleSearch);

    return () => {
      window.removeEventListener('performSearch', handleSearch);
    };
  }, [getGovernanceData]);

  // Add this new function
  const resetExpandedStates = () => {
    setExpandedSeat(null);
    setExpandedInnerSeat(null);
    setExpandedTable(null);
    setActiveLogo('/tables_seats/xahau.png');
    setExpandedTableName('Pick a Table or Seat');
    setSelectedItem(null);
  };

  useEffect(() => {
    if (searchQuery) {
      setIsLoading(true);
      resetExpandedStates(); // Add this line to reset states when searching
      fetchAccountDetails(searchQuery)
        .then(result => {
          if (result) {
            setWalletData(result);
            setSelectedTransactions(result.transactions || []);
            setSearchedWallet(searchQuery);
          } else {
            alert('Wallet not found. Please check the address and try again.');
          }
        })
        .catch(error => {
          console.error('Error searching wallet:', error);
          alert('An error occurred while searching for the wallet. Please try again.');
        })
        .finally(() => {
          setIsLoading(false);
          onSearchComplete();
        });
    }
  }, [searchQuery, searchTrigger]);

  useEffect(() => {
    if (searchQuery) {
      setSearchedWallet(searchQuery);
    }
  }, [searchQuery, onSearchComplete]);

  useEffect(() => {
    // Check for search query from navigation state or props
    const stateSearchQuery = location.state?.searchQuery;
    if (stateSearchQuery) {
      setSearchQuery(stateSearchQuery);
      // Clear the state to prevent re-searching on component re-render
      window.history.replaceState({}, document.title);
    } else if (propSearchQuery) {
      setSearchQuery(propSearchQuery);
    }
  }, [location.state, propSearchQuery]);

  return (
    <div className="governance-container">
      <div className="transaction-box-container">
        <TransactionBox 
          transactionData={isSearchedWallet ? walletData?.transactions : selectedTransactions}
          isLoading={isLoading}
          searchTrigger={searchTrigger}
        />
      </div>
      <div className="governance-diagram">
        <svg className="governance-svg" viewBox="-250 -250 500 500">
          <defs>
            <radialGradient id="centralGradient">
              <stop offset="0%" stopColor="#61dafb" />
              <stop offset="100%" stopColor="#0f172a" />
            </radialGradient>
            <filter id="glow">
              <feGaussianBlur stdDeviation="3.5" result="coloredBlur"/>
              <feMerge>
                <feMergeNode in="coloredBlur"/>
                <feMergeNode in="SourceGraphic"/>
              </feMerge>
            </filter>
          </defs>

          {/* Central group */}
          <g className={`central-group ${expandedSeat !== null || expandedTable !== null ? 'seat-expanded' : ''}`}>
            <circle cx="0" cy="0" r="100" className="central-circle" />
            <text x="0" y="-40" textAnchor="middle" className="central-text ledger-title">
              Current Ledger:
            </text>
            <text x="0" y="-25" textAnchor="middle" className="central-text ledger-count">
              {ledgerIndex.toLocaleString()}
            </text>
            <text x="0" y="0" textAnchor="middle" className="central-text">
              Xahau Governance Game
            </text>
            <text x="0" y="25" textAnchor="middle" className="central-text reward-rate">
              Balance Adjustment:
            </text>
            <text x="0" y="40" textAnchor="middle" className="central-text reward-rate-value">
              {rewardRate !== null ? '4.00%' : 'Loading...'}
            </text>
            
            {/* Toggle switch */}
            <g 
              className={`toggle-switch ${showAllColored ? 'active' : ''}`} 
              transform="translate(0, 70)" 
              onClick={handleToggleClick}
            >
              <rect 
                x="-20" 
                y="-8" 
                width="40" 
                height="16" 
                rx="8" 
                ry="8" 
                className="toggle-background"
              />
              <circle 
                cx={showAllColored ? "13" : "-13"}
                cy="0" 
                r="6" 
                className="toggle-circle"
              />
            </g>
          </g>

          {/* Layer 1 Tables */}
          <Layer1Tables
            rotatedPositions={rotatedLayer1Positions}
            expandedTable={expandedTable}
            activeWallet={activeWallet}
            handleTableClick={handleTableClick}
            handleWalletClick={handleWalletClick}
            expandedSeat={expandedSeat}
            setActiveLogo={setActiveLogo}
            showAllColored={showAllColored}
            setExpandedTableName={setExpandedTableName}
            governanceData={governanceData}
            setSelectedAccountId={setSelectedAccountId}
            setSelectedAccountBalance={setSelectedAccountBalance} // Add this prop
            setIsLoading={setIsLoading} // Add this prop
            getSeatColor={getSeatColor}
            setSelectedTokens={setSelectedTokens}
            isInteractionAllowed={isInteractionAllowed}
            setIsInteractionAllowed={setIsInteractionAllowed}
            fetchAccountData={fetchAccountData}
            setSelectedTransactions={setSelectedTransactions}
            resetExpandedStates={resetExpandedStates}
          />

          {/* Mapping of Layer 1 Seats (which are Layer 2 Tables) */}
          {rotatedLayer1Positions.map((pos, i) => {
            const seatNumber = i;
            const isExpanded = expandedSeat === i;
            const isWalletActive = activeWallet && activeWallet.layer === 'outer' && activeWallet.index === i;
            const logoInfo = layer1SeatLogos[i] || { logo: null, name: 'Vacant Table' };
            const logoSrc = logoInfo.logo || getDefaultLogo();
            const seatColor = getSeatColor('outer', i);

            // Get Layer 2 Table data
            const layer2TableData = governanceData && governanceData.layer2Tables ? governanceData.layer2Tables[i] : null;

            return (
              <g 
                key={`layer1-${i}`} 
                onClick={(e) => handleSeatClick('outer', i, e)}
                className={`layer1-group ${isExpanded ? 'expanded' : ''} ${isWalletActive ? 'wallet-active' : ''} ${seatColor}`}
                style={{
                  '--translate-x': isExpanded ? '0px' : `${pos.x}px`,
                  '--translate-y': isExpanded ? '0px' : `${pos.y}px`,
                  '--scale': isExpanded ? 5 : 1,
                  cursor: isInteractionAllowed ? 'pointer' : 'not-allowed',
                }}
              >
                <circle 
                  cx="0" 
                  cy="0" 
                  r="23" 
                  className={`layer1-seat seat-${seatNumber} ${seatColor}`} 
                />
                <g className={`seat-content ${isWalletActive ? 'hidden' : ''}`}>
                  <text x="0" y="-7" textAnchor="middle" className="seat-text">{seatNumber}</text>
                  {logoInfo.logo ? (
                    <image 
                      href={logoSrc}
                      x="-15" 
                      y="-15" 
                      width="30" 
                      height="30"
                      onError={(e) => {
                        e.target.href = getDefaultLogo();
                      }}
                    />
                  ) : (
                    logoInfo.name.length > 10 ? (
                      <>
                        {logoInfo.name.split(' ').reduce((acc, word) => {
                          const lastLine = acc[acc.length - 1];
                          if ((lastLine + ' ' + word).trim().length <= 15) {
                            acc[acc.length - 1] = lastLine + ' ' + word;
                          } else {
                            acc.push(word);
                          }
                          return acc;
                        }, ['']).map((line, index) => (
                          <text 
                            key={index}
                            x="0" 
                            y={0 + index * 3.5}  // Moved down by changing the initial y position
                            textAnchor="middle" 
                            className="seat-text no-logo"
                            style={{
                              fontSize: isExpanded ? (logoInfo.name.length > 10 ? '4px' : '5px') : '4px', // Increased font size
                              fontWeight: 'bold'
                            }}
                          >
                            {line.trim()} {/* Render each line */}
                          </text>
                        ))}
                      </>
                    ) : (
                      <text 
                        x="0" 
                        y="0"  // Adjusted y value to move the text down
                        textAnchor="middle" 
                        className="seat-text no-logo"
                        style={{
                          fontSize: isExpanded ? (logoInfo.name.length > 10 ? '4px' : '5px') : '4px', // Increased font size
                          fontWeight: 'bold',
                          transition: 'font-size 0.3s ease'
                        }}
                      >
                        {logoInfo.name}
                      </text>
                    )
                  )}
                  <text 
                    x="0" 
                    y="10" 
                    textAnchor="middle" 
                    className="seat-title"
                    style={{
                      fontSize: isExpanded ? '3.5px' : '2.5px',
                      fontWeight: 'bold',
                      transition: 'font-size 0.3s ease'
                    }}
                  >
                    Layer 2 Table
                  </text>
                </g>
                {isWalletActive && (
                  <g className="wallet-view">
                    {logoInfo.logo ? (
                      <image 
                        href={logoSrc}
                        x="-15" 
                        y="-18" 
                        width="30" 
                        height="30"
                        onError={(e) => {
                          e.target.src = getDefaultLogo();
                        }}
                      />
                    ) : (
                      <text 
                        x="0" 
                        y="0" 
                        textAnchor="middle" 
                        className="seat-text no-logo"
                        style={{
                          fontSize: logoInfo.name.length > 10 ? '3px' : '4px',
                          fontWeight: 'bold'
                        }}
                      >
                        {logoInfo.name}
                      </text>
                    )}
                    <WalletView 
                      walletAddress={layer2TableData ? layer2TableData.accountId : `rLayer2Table${i}`}
                      balance="5892402" 
                      isInner={false}
                      logo={logoSrc}
                    />
                  </g>
                )}

                {/* Inner seats rendering */}
                {rotatedInnerPositions.base.map((innerPos, j) => {
                  const innerSeatNumber = j;
                  const isInnerExpanded = isExpanded && expandedInnerSeat === j;
                  const expandedInnerPos = rotatedInnerPositions.expanded[j];
                  const isInnerWalletActive = activeWallet && activeWallet.layer === 'inner' && activeWallet.index === `${i}-${j}`;
                  const innerLogoInfo = layer2SeatLogos[`${i}-${j}`] || { logo: null, name: 'Vacant Seat' };
                  const innerLogoSrc = innerLogoInfo.logo || getDefaultLogo();
                  const innerSeatColor = getSeatColor('inner', i, j);

                  // Get Layer 2 Seat data
                  const layer2SeatData = governanceData && governanceData.layer2Seats && 
                                         governanceData.layer2Seats[i] && 
                                         governanceData.layer2Seats[i].seats ? 
                                         governanceData.layer2Seats[i].seats[j] : null;

                  return (
                    <g
                      key={`inner-${i}-${j}`}
                      onClick={(e) => handleInnerSeatClick(i, j, e)}
                      className={`inner-circle-group inner-circle-group-${i} ${innerSeatColor}`}
                      style={{
                        '--inner-translate-x': isExpanded ? (isInnerExpanded ? '0px' : `${expandedInnerPos.x}px`) : `${innerPos.x}px`,
                        '--inner-translate-y': isExpanded ? (isInnerExpanded ? '0px' : `${expandedInnerPos.y}px`) : `${innerPos.y}px`,
                        '--inner-scale': isExpanded ? (isInnerExpanded ? 5.52 : 1.1) : 1,
                        cursor: isInteractionAllowed ? 'pointer' : 'not-allowed',
                      }}
                    >
                      <circle
                        cx="0"
                        cy="0"
                        r="4"
                        className={`inner-circle inner-circle-${j} ${innerSeatColor}`}
                      />
                      <g className={`inner-seat-content ${isInnerWalletActive ? 'hidden' : ''}`}>
                        {isExpanded && (
                          <>
                            <text x="0" y="-1.5" textAnchor="middle" className="inner-seat-text">{innerSeatNumber}</text>
                            {innerLogoInfo.logo ? ( // Check if logo exists
                              <image 
                                href={innerLogoSrc}
                                x="-3" 
                                y="-3" 
                                width="6" 
                                height="6"
                                onError={(e) => {
                                  e.target.src = getDefaultLogo();
                                }}
                              />
                            ) : (
                              // Keep the existing logic for displaying text
                              innerLogoInfo.name.length > 10 ? (
                                <>
                                  {innerLogoInfo.name.split(' ').reduce((acc, word) => {
                                    const lastLine = acc[acc.length - 1];
                                    if ((lastLine + ' ' + word).trim().length <= 15) {
                                      acc[acc.length - 1] = lastLine + ' ' + word;
                                    } else {
                                      acc.push(word);
                                    }
                                    return acc;
                                  }, ['']).map((line, index) => (
                                    <text 
                                      key={index}
                                      x="0" 
                                      y={0 + index * 0.75}  // Adjusted y value for better centering
                                      textAnchor="middle" 
                                      className="inner-seat-text"
                                      style={{
                                        fontSize: isInnerExpanded ? '0.8px' : '0.6px',
                                        fontWeight: 'bold',
                                        transition: 'font-size 0.3s ease'
                                      }}
                                    >
                                      {line.trim()} {/* Render each line */}
                                    </text>
                                  ))}
                                </>
                              ) : (
                                <text 
                                  x="0" 
                                  y="0" 
                                  textAnchor="middle" 
                                  className="inner-seat-text no-logo"
                                  style={{
                                    fontSize: isInnerExpanded ? '0.8px' : '0.6px',
                                    fontWeight: 'bold',
                                    transition: 'font-size 0.3s ease'
                                  }}
                                >
                                  {innerLogoInfo.name}
                                </text>
                              )
                            )}
                            <text 
                              x="0" 
                              y="2" 
                              textAnchor="middle" 
                              className="inner-seat-title"
                              style={{
                                fontSize: '0.7px',
                                fontWeight: 'bold',
                                transition: 'font-size 0.3s ease'
                              }}
                            >
                              Layer 2 Seat
                            </text>
                          </>
                        )}
                      </g>
                      {isExpanded && isInnerWalletActive && (
                        <g className="inner-wallet-view">
                          {innerLogoInfo.logo ? (
                            <image 
                              href={innerLogoSrc}
                              x="-2" 
                              y="-2.5" 
                              width="5" 
                              height="5"
                              onError={(e) => {
                                e.target.src = getDefaultLogo();
                              }}
                            />
                          ) : (
                            <text 
                              x="0" 
                              y="0" 
                              textAnchor="middle" 
                              className="inner-seat-text no-logo"
                              style={{
                                fontSize: '0.8px',
                                fontWeight: 'bold'
                              }}
                            >
                              {innerLogoInfo.name}
                            </text>
                          )}
                          <WalletView 
                            walletAddress={layer2SeatData ? layer2SeatData.wallet : `rInnerSeatWallet${i}-${j}`}
                            balance="1000000" 
                            isInner={true}
                            logo={innerLogoSrc}
                          />
                        </g>
                      )}
                    </g>
                  );
                })}
              </g>
            );
          })}
        </svg>
      </div>
      <div className="wallet-stats-container">
        <WalletStats 
          activeLogo={activeLogo}
          selectedItem={selectedItem}
          expandedTableName={expandedTableName}
          governanceData={governanceData}
          xahAccount={searchedWallet || selectedAccountId}
          xahBalance={searchedWallet ? walletData?.balance : selectedAccountBalance}
          isLoading={isLoading}
          tokens={searchedWallet ? walletData?.tokens : selectedTokens}
          isWalletSearched={!!searchedWallet}
          searchTrigger={searchTrigger}
          hookData={walletData?.ledgerObjects?.[0]?.Hooks || []}
          expandedTable={expandedTable}
          expandedSeat={expandedSeat}
          expandedInnerSeat={expandedInnerSeat}
        />
      </div>
    </div>
  );
};

export default Governance;