import React from 'react';
import { useParams } from 'react-router-dom';

const ContractDetails = ({ contracts }) => {
  const { id } = useParams();
  const contract = contracts.find(c => c.id === parseInt(id));

  if (!contract) {
    return <div>Contract not found</div>;
  }

  return (
    <div className="contract-details">
      <h1>{contract.name}</h1>
      <p>{contract.description}</p>
      <p>Price: {contract.price} XAH</p>
      {/* Add more details and functionality as needed */}
    </div>
  );
};

export default ContractDetails;
