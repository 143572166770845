import React from 'react';
import '../StarryBackground.css';

const StarryBackground = () => {
  return (
    <div className="starry-background">
      {[...Array(30)].map((_, i) => (
        <div key={i} className="star"></div>
      ))}
    </div>
  );
};

export default StarryBackground;