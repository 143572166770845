import React, { useState, useEffect } from 'react';
import './WalletStats.css';
import { getDefaultLogo } from './SeatLogos';

// Import the getCurrencyColor function or define it here
const getCurrencyColor = (currency) => {
  switch (currency) {
    case 'XAH':
      return 'white';
    case 'EVR':
      return '#00BFFF'; // Deep Sky Blue
    case 'USD':
      return '#32CD32'; // Lime Green
    case 'XRP':
      return '#4169E1'; // Royal Blue
    case 'BTC':
      return '#FFA500'; // Orange
    case 'ETH':
      return '#8A2BE2'; // Blue Violet
    case 'MAG':
      return '#FF1493'; // Deep Pink
    case 'CLV':
      return '#00CED1'; // Dark Turquoise
    case 'ETX':
      return '#FF6347'; // Tomato
    case 'BZO':
      return '#20B2AA'; // Light Sea Green
    case 'XTT':
      return '#9370DB'; // Medium Purple
    case 'FLY':
      return '#FFD700'; // Gold
    default:
      return 'white';
  }
};

const WalletStats = ({ 
  activeLogo, 
  selectedItem, 
  expandedTableName, 
  governanceData, 
  xahAccount, 
  xahBalance, 
  isLoading, 
  tokens,
  isWalletSearched,
  searchTrigger,
  hookData,
  expandedTable,
  expandedSeat,
  expandedInnerSeat
}) => {
  const [currentLogo, setCurrentLogo] = useState(getDefaultLogo());
  const [displayData, setDisplayData] = useState(null);
  const [isTransitioning, setIsTransitioning] = useState(false);
  
  const [walletData, setWalletData] = useState({
    walletName: 'Select a Table or Seat',
    xahAccount: '--',
    xahBalance: '--',
    hookSlots: [
      { id: 0, status: 'empty' },
      { id: 1, status: 'empty' },
      { id: 2, status: 'empty' },
      { id: 3, status: 'empty' },
      { id: 4, status: 'empty' },
      { id: 5, status: 'empty' },
      { id: 6, status: 'empty' },
      { id: 7, status: 'empty' },
      { id: 8, status: 'empty' },
      { id: 9, status: 'empty' },
    ],
  });

  const [hookSlots, setHookSlots] = useState([
    { id: 0, status: 'empty' },
    { id: 1, status: 'empty' },
    { id: 2, status: 'empty' },
    { id: 3, status: 'empty' },
    { id: 4, status: 'empty' },
    { id: 5, status: 'empty' },
    { id: 6, status: 'empty' },
    { id: 7, status: 'empty' },
    { id: 8, status: 'empty' },
    { id: 9, status: 'empty' },
  ]);

  useEffect(() => {
    setIsTransitioning(true);
    setDisplayData(null);
    
    // Prepare the new data
    const newData = {
      walletName: isWalletSearched ? 'Xahau Wallet' : (expandedTableName || 'Select a Table or Seat'),
      xahAccount: xahAccount || '--',
      xahBalance: xahBalance !== undefined ? xahBalance : '--',
      logo: isWalletSearched ? getDefaultLogo() : (activeLogo || getDefaultLogo())
    };

    // Use a short timeout to ensure smooth transition
    setTimeout(() => {
      setDisplayData(newData);
      setIsTransitioning(false);
    }, 50);
  }, [isWalletSearched, expandedTableName, xahAccount, xahBalance, activeLogo, searchTrigger]);

  useEffect(() => {
    let newName = 'Select a Table or Seat';
    let newBalance = xahBalance !== undefined ? xahBalance : '--';

    if (isWalletSearched) {
      newName = 'Xahau Wallet';
    } else if (expandedTableName) {
      newName = expandedTableName;
    }

    setWalletData(prevData => ({
      ...prevData,
      walletName: newName,
      xahAccount: xahAccount || '--',
      xahBalance: newBalance
    }));
  }, [selectedItem, expandedTableName, governanceData, xahAccount, xahBalance, isWalletSearched]);

  useEffect(() => {
    // Update hook slots based on hookData
    const updatedHookSlots = hookSlots.map((slot, index) => {
      const hookInfo = hookData[index]?.Hook;
      return {
        ...slot,
        status: hookInfo && hookInfo.HookHash ? 'active' : 'empty'
      };
    });
    setHookSlots(updatedHookSlots);
  }, [hookData]);

  const handleSlotClick = (id) => {
    // Placeholder for future action
    console.log(`Slot ${id} clicked`);
  };

  const LoadingDots = () => (
    <span className="loading-dots">
      <span></span><span></span><span></span>
    </span>
  );

  const shouldDisplayBalance = isWalletSearched || expandedTable !== null || expandedSeat !== null || expandedInnerSeat !== null;
  const displayBalance = shouldDisplayBalance ? xahBalance : '--';

  return (
    <div className="wallet-stats">
      {/* Commented out Xamini logo
      <div className="wallet-stats__xamini-logo">
        <img 
          src="/tables_seats/xamini_round.png" 
          alt="Xamini" 
          className="wallet-stats__xamini-img"
        />
      </div>
      */}

      <div className="wallet-stats__header">
        <img 
          src={displayData ? displayData.logo : getDefaultLogo()}
          alt="Table Logo" 
          className="wallet-stats__logo"
          onError={(e) => {
            console.error('Error loading image:', e);
            e.target.src = getDefaultLogo();
          }}
        />
      </div>
      <div className="wallet-stats__wallet-info">
        <h2 className="wallet-stats__wallet-name">
          {isLoading || isTransitioning ? <LoadingDots /> : displayData?.walletName}
        </h2>
        <div className="wallet-stats__xah-account-box">
          <span className="wallet-stats__xah-value">
            {isLoading || isTransitioning ? <LoadingDots /> : displayData?.xahAccount}
          </span>
        </div>
        <div className="wallet-stats__xah-balance-box">
          <span className="wallet-stats__xah-value">
            {isLoading || isTransitioning ? <LoadingDots /> : (
              typeof displayBalance === 'number' || (typeof displayBalance === 'string' && displayBalance !== '--')
                ? (() => {
                    const [wholePart, fractionalPart] = displayBalance.toString().split('.');
                    return (
                      <>
                        {wholePart}
                        {fractionalPart && (
                          <>
                            <span className="wallet-stats__xah-decimal">.</span>
                            <span className="wallet-stats__xah-drops">{fractionalPart}</span>
                          </>
                        )}
                        {' XAH'}
                      </>
                    );
                  })()
                : '--'
            )}
          </span>
        </div>
      </div>
      
      {/* Token Table Section */}
      <div className="wallet-stats__token-table">
        <div className="token-table__header">
          <span>Currency</span>
          <span>Balance</span>
        </div>
        <div className="token-table__body">
          {isLoading ? (
            <div className="token-table__item">
              <span className="token-table__ticker"><LoadingDots /></span>
              <span className="token-table__amount"><LoadingDots /></span>
            </div>
          ) : xahAccount && xahAccount !== '--' ? (
            tokens && tokens.length > 0 ? (
              tokens.map((token, index) => (
                <div key={token.currency || index} className="token-table__item">
                  <span 
                    className="token-table__ticker"
                    style={{ color: getCurrencyColor(token.currency) }}
                  >
                    {token.currency || '--'}
                  </span>
                  <span 
                    className="token-table__amount"
                    style={{ color: getCurrencyColor(token.currency) }}
                  >
                    {token.balance || '--'}
                  </span>
                </div>
              ))
            ) : (
              <div className="token-table__item">
                <span className="token-table__ticker">No Trustlines </span>
                <span className="token-table__amount">--</span>
              </div>
            )
          ) : (
            <div className="token-table__item">
              <span className="token-table__ticker">--</span>
              <span className="token-table__amount">--</span>
            </div>
          )}
        </div>
      </div>
      
      <div className="wallet-stats__hook-slots-container">
        <div className="wallet-stats__hook-slots">
          {hookSlots.map((slot) => (
            <div
              key={slot.id}
              className={`wallet-stats__hook-slot ${slot.status}`}
              onClick={() => handleSlotClick(slot.id)}
            >
              <span className="wallet-stats__hook-number">Hook {slot.id}</span>
              <span className="wallet-stats__hook-status">{slot.status.charAt(0).toUpperCase() + slot.status.slice(1)}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WalletStats;
