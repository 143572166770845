import React from 'react';
import './Ecosystem.css';

const ecosystemData = [
  {
    category: "Official Links",
    entities: [
      { logo: "offical_xahau.png", url: "https://xahau.network/" },
      { logo: "xahau_docs.png", url: "https://docs.xahau.network/" },
      { logo: "hook_docs.png", url: "https://xrpl-hooks.readme.io/" },
      { logo: "xahau_github.png", url: "https://github.com/Xahau" },
      { logo: "xahau_x_2.png", url: "https://x.com/XahauNetwork" },
    ]
  },
  {
    category: "Educational & Foundations",
    entities: [
      { logo: "inftf.png", url: "https://inftf.org" },
      { logo: "digital_governing.png", url: "https://digitalgoverning.eu" },
    ]
  },
  {
    category: "Infrastructure Providers",
    entities: [
      { logo: "xaman.png", url: "https://xaman.app" },
      { logo: "titanium.png", url: "http://titanium.ee" },
      { logo: "evernode.png", url: "https://evernode.org" },
      { logo: "xrplwin.png", url: "https://xahau.xrplwin.com/" },
      { logo: "bithomp.png", url: "https://xahauexplorer.com/" },
      { logo: "xrpscan.png", url: "https://xahscan.com/" },
      { logo: "casinocoin.png", url: "https://casinocoin.org" },
      { logo: "xspectar.png", url: "https://xspectar.com" },
    ]
  },
  {
    category: "Exchanges & DEX",
    entities: [
      { logo: "bitrue.png", url: "https://www.bitrue.com" },
      { logo: "gatehub.png", url: "https://gatehub.net" },
      { logo: "magnetic.png", url: "https://xmagnetic.org/?network=mainnet" },
      { logo: "unhosted.png", url: "https://unhosted.exchange" },
      { logo: "c14_team.png", url: "https://www.c14.money" },
    ]
  },
  {
    category: "Core Developers",
    entities: [
      { logo: "tequ.png", url: "https://tequ.dev" },
      { logo: "richard.png", url: "https://x.com/RichardXRPL" },
      { logo: "denis.png", url: "https://x.com/angell_denis" },
      { logo: "satish.png", url: "https://x.com/Satish_nl" },
    ]
  },
  {
    category: "Events & Media",
    entities: [
      { logo: "wave.png", url: "https://www.waveofinnovation.com" },
      { logo: "casa.png", url: "https://xahau.casa" },
    ]
  },
  {
    category: "Communities",
    entities: [
      { logo: "xahau_discord.png", url: "https://discord.gg/xSHhkX4cjy" },
      { logo: "xahau_x.png", url: "https://x.com/XahauCommunity" },
    ]
  },
];

const Ecosystem = () => {
  return (
    <div className="ecosystem-page">
      <h1>Xahau Ecosystem</h1>
      <p>Explore the diverse network of entities contributing to the growth and development of the Xahau ecosystem.</p>
      
      <div className="ecosystem-grid">
        {ecosystemData.map((category, index) => (
          <div key={index} className="category-section">
            <h2>{category.category}</h2>
            <div className="entity-grid">
              {category.entities.map((entity, entityIndex) => (
                <a 
                  key={entityIndex} 
                  href={entity.url} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="entity-box"
                >
                  <img src={`${process.env.PUBLIC_URL}/tables_seats/${entity.logo}`} alt="" />
                </a>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Ecosystem;
