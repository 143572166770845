import React from 'react';
import './Validators.css';

const Validators = () => {
  const xahauMetrics = {
    totalValidators: 150,
    activeValidators: 100,
    totalStaked: '500,000,000 XAH',
    averageUptime: '99.98%',
    currentReward: '5.2% APY',
    minStakeRequired: '10,000 XAH',
  };

  const evernodeMetrics = {
    totalHosts: 500,
    activeHosts: 450,
    totalLeased: '1,000,000 EVR',
    averageUptime: '99.95%',
    currentReward: '7.5% APY',
    minLeaseRequired: '5,000 EVR',
  };

  return (
    <div className="validators-page">
      <h1>Validators</h1>
      <div className="validators-container">
        <div className="validator-card xahau">
          <h2>Xahau Validator</h2>
          <div className="metric">
            <span>Total Validators:</span>
            <span>{xahauMetrics.totalValidators}</span>
          </div>
          <div className="metric">
            <span>Active Validators:</span>
            <span>{xahauMetrics.activeValidators}</span>
          </div>
          <div className="metric">
            <span>Total Staked:</span>
            <span>{xahauMetrics.totalStaked}</span>
          </div>
          <div className="metric">
            <span>Average Uptime:</span>
            <span>{xahauMetrics.averageUptime}</span>
          </div>
          <div className="metric">
            <span>Current Reward:</span>
            <span>{xahauMetrics.currentReward}</span>
          </div>
          <div className="metric">
            <span>Min Stake Required:</span>
            <span>{xahauMetrics.minStakeRequired}</span>
          </div>
          <button className="validator-action">Become a Xahau Validator</button>
        </div>
        <div className="validator-card evernode">
          <h2>Evernode Validator</h2>
          <div className="metric">
            <span>Total Hosts:</span>
            <span>{evernodeMetrics.totalHosts}</span>
          </div>
          <div className="metric">
            <span>Active Hosts:</span>
            <span>{evernodeMetrics.activeHosts}</span>
          </div>
          <div className="metric">
            <span>Total Leased:</span>
            <span>{evernodeMetrics.totalLeased}</span>
          </div>
          <div className="metric">
            <span>Average Uptime:</span>
            <span>{evernodeMetrics.averageUptime}</span>
          </div>
          <div className="metric">
            <span>Current Reward:</span>
            <span>{evernodeMetrics.currentReward}</span>
          </div>
          <div className="metric">
            <span>Min Lease Required:</span>
            <span>{evernodeMetrics.minLeaseRequired}</span>
          </div>
          <button className="validator-action">Become an Evernode Host</button>
        </div>
      </div>
    </div>
  );
};

export default Validators;