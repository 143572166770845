import React, { useState, useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
} from 'chart.js';
import 'chartjs-adapter-date-fns'; // You'll need to install this

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const PriceChart = () => {
  const [xahData, setXahData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const chartRef = useRef(null);

  useEffect(() => {
    const fetchPrice = async () => {
      try {
        const response = await fetch('https://openapi.bitrue.com/api/v1/market/kline?symbol=XAHUSDT&scale=1h&limit=24');

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.msg || `HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('API Response:', data);
        
        if (data.data && Array.isArray(data.data)) {
          const formattedData = data.data.map(candle => ({
            x: new Date(candle.is).getTime(),
            y: parseFloat(candle.c)
          }));
          setXahData(formattedData);
        } else {
          throw new Error('Invalid data format received from API');
        }
        
        setIsLoading(false);
      } catch (err) {
        console.error('Error fetching price:', err);
        setError(err.message);
        setIsLoading(false);
      }
    };

    fetchPrice();
    const interval = setInterval(fetchPrice, 300000); // Update every 5 minutes

    return () => clearInterval(interval);
  }, []);

  const createChartData = (data) => ({
    datasets: [
      {
        data: data,
        borderColor: '#61dafb',
        backgroundColor: 'rgba(97, 218, 251, 0.1)',
        fill: true,
        tension: 0.4
      }
    ]
  });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'hour',
          displayFormats: {
            hour: 'HH:mm'
          }
        },
        ticks: {
          maxTicksLimit: 6,
          color: 'rgba(255, 255, 255, 0.7)'
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.1)'
        }
      },
      y: {
        ticks: {
          callback: function(value) {
            return '$' + value.toFixed(4);
          },
          color: 'rgba(255, 255, 255, 0.7)'
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.1)'
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            return '$' + context.parsed.y.toFixed(4);
          }
        },
        backgroundColor: 'rgba(0, 0, 0, 0.7)'
      }
    }
  };

  return (
    <div className="price-chart-container" style={{
      maxWidth: '800px',
      margin: '0 auto 120px auto',
      padding: '0 20px'
    }}>
      <div className="chart-wrapper">
        <h2>XAH Price (24h)</h2>
        {isLoading ? (
          <div className="loading">Loading price data...</div>
        ) : error ? (
          <div className="error">{error}</div>
        ) : (
          <>
            <div className="current-price" style={{
              fontSize: '24px',
              fontWeight: 'bold',
              color: '#ffffff',
              marginBottom: '20px',
              textAlign: 'center'
            }}>
              ${xahData.length > 0 ? xahData[xahData.length - 1].y.toFixed(4) : '0.0000'}
            </div>
            <div style={{ height: '400px' }}>
              <Line ref={chartRef} options={options} data={createChartData(xahData)} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PriceChart;