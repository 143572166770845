import React from 'react';

const WalletView = ({ walletAddress, balance, isInner, logo }) => {
  return (
    <div className="wallet-stats">
      <div className="wallet-stats__header">
        <img src={logo} alt="Wallet Logo" className="wallet-stats__logo" />
      </div>
      <div>Wallet Address: {walletAddress}</div>
      <div>Balance: {balance}</div>
      {/* ... rest of the component ... */}
    </div>
  );
};

export default WalletView;