import { decodeAccountId, decodeXFL } from './decoders';

// Add this at the top of the file
let apiCallCount = 0;
let lastFetchTime = 0;
let cachedData = null;
const CACHE_DURATION = 5000; // 5 seconds cache duration
let fetchPromise = null;

export const fetchGovernanceData = async () => {
  const now = Date.now();

  // If we have cached data and it's still fresh, return it
  if (cachedData && now - lastFetchTime < CACHE_DURATION) {
    // console.log('Returning cached data');
    return cachedData;
  }

  // If there's an ongoing fetch, return its promise
  if (fetchPromise) {
    // console.log('Returning ongoing fetch promise');
    return fetchPromise;
  }

  // Reset the counter and start a new fetch
  apiCallCount = 0;
  fetchPromise = fetchGovernanceDataInternal();

  try {
    cachedData = await fetchPromise;
    lastFetchTime = Date.now();
    return cachedData;
  } finally {
    fetchPromise = null;
  }
};

const fetchGovernanceDataInternal = async () => {
  try {
    // Reset the counter at the start of each fetchGovernanceData call
    apiCallCount = 0;

    apiCallCount++;
    const response = await fetch('https://xahau.network/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        method: "account_namespace",
        params: [{
          account: "rHb9CJAWyB4rj91VRWn96DkukG4bwdtyTh",
          namespace_id: "0000000000000000000000000000000000000000000000000000000000000000",
          ledger_index: "validated"
        }]
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    if (data.result && data.result.namespace_entries) {
      // console.log('Raw Parsed Data:', data.result.namespace_entries);

      const processedData = {
        ledgerIndex: data.result.ledger_index,
        rewardDelay: 0,
        rewardRate: 0,
        layer1Tables: {},
        layer2Tables: {},
        layer2Seats: {}
      };

      let layer1MemberCount = 0;

      // First pass: get the Layer 1 member count and other singleton entries
      for (const entry of data.result.namespace_entries) {
        const key = entry.HookStateKey;
        const value = entry.HookStateData;

        if (key === '0000000000000000000000000000000000000000000000000000000000004D43') {
          layer1MemberCount = parseInt(value, 16);
        } else if (key === '0000000000000000000000000000000000000000000000000000000000005252') {
          processedData.rewardRate = decodeXFL(value);
        } else if (key === '0000000000000000000000000000000000000000000000000000000000005244') {
          processedData.rewardDelay = decodeXFL(value);
        }
      }

      // Second pass: process all entries for potential Layer 1 tables
      const potentialLayer1Tables = {};
      for (const entry of data.result.namespace_entries) {
        const key = entry.HookStateKey;
        const value = entry.HookStateData;

        if (key.startsWith('00000000000000000000000000000000000000000000000000000000000000')) {
          const seatNumber = parseInt(key.slice(-2), 16);
          const accountId = decodeAccountId(value);
          
          if (seatNumber <= layer1MemberCount) {
            potentialLayer1Tables[seatNumber] = accountId;
          }
        }
      }

      // Process Layer 2 Tables and Seats
      let layer2TablesArray = [];
      for (const [seatNumber, accountId] of Object.entries(potentialLayer1Tables)) {
        try {
          const accountInfo = await fetchAccountInfo(accountId);
          if (accountInfo && accountInfo.HookStateCount > 0) {
            const layer2Data = await fetchLayer2Data(accountId);
            layer2TablesArray.push({
              seatNumber: parseInt(seatNumber),
              accountId: accountId,
              layer2Data: layer2Data
            });
          } else {
            processedData.layer1Tables[seatNumber] = { accountId };
          }
        } catch (error) {
          // console.error(`Error checking account status for seat ${seatNumber}:`, error);
          processedData.layer1Tables[seatNumber] = { accountId };
        }
      }

      // Sort layer2TablesArray by seatNumber
      layer2TablesArray.sort((a, b) => a.seatNumber - b.seatNumber);

      // Assign indices to layer2Tables and layer2Seats maintaining relative gaps
      if (layer2TablesArray.length > 0) {
        const minSeatNumber = layer2TablesArray[0].seatNumber;
        layer2TablesArray.forEach((table) => {
          const relativeIndex = table.seatNumber - minSeatNumber;
          processedData.layer2Tables[relativeIndex] = {
            seatNumber: table.seatNumber,
            accountId: table.accountId
          };
          if (table.layer2Data) {
            processedData.layer2Seats[relativeIndex] = {
              seats: {}
            };
            for (const [innerSeatNumber, innerAccountId] of Object.entries(table.layer2Data.seats)) {
              processedData.layer2Seats[relativeIndex].seats[innerSeatNumber] = {
                accountId: innerAccountId
              };
            }
          }
        });
      }

      // console.log('Processed Data:', processedData);

      // Save processed data to localStorage
      saveDataToLocalStorage(processedData);

      // At the end of the function, before returning:
      // console.log(`Total API calls made: ${apiCallCount}`);

      return processedData;
    } else {
      // console.error('Unexpected response structure:', data);
      return null;
    }
  } catch (error) {
    // console.error('Error fetching governance data:', error);
    // console.log(`Total API calls made: ${apiCallCount}`);
    return null;
  }
};

// New function to save data to localStorage
function saveDataToLocalStorage(data) {
  try {
    const dataWithTimestamp = {
      data: data,
      timestamp: Date.now()
    };
    const jsonData = JSON.stringify(dataWithTimestamp);
    localStorage.setItem('governanceData', jsonData);
    // console.log('Governance data saved to localStorage');
  } catch (error) {
    // console.error('Error saving governance data to localStorage:', error);
  }
}

// New function to retrieve data from localStorage
export function getGovernanceDataFromStorage() {
  try {
    const jsonData = localStorage.getItem('governanceData');
    if (jsonData) {
      return JSON.parse(jsonData);
    }
    return null;
  } catch (error) {
    // console.error('Error retrieving governance data from localStorage:', error);
    return null;
  }
};
const fetchAccountInfo = async (account) => {
  try {
    apiCallCount++;
    const response = await fetch('https://xahau.network/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        method: "account_info",
        params: [{
          account: account,
          ledger_index: "validated"
        }]
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    
    return data.result.account_data;
  } catch (error) {
    // console.error('Error fetching account info:', error);
    return null;
  }
};

const fetchLayer2Data = async (account) => {
  try {
    apiCallCount++;
    const response = await fetch('https://xahau.network/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        method: "account_namespace",
        params: [{
          account: account,
          namespace_id: "0000000000000000000000000000000000000000000000000000000000000000",
          ledger_index: "validated"
        }]
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    
    if (data.result && data.result.namespace_entries) {
      const layer2Data = {
        seats: {},
        memberCount: 0
      };

      for (const entry of data.result.namespace_entries) {
        const key = entry.HookStateKey;
        const value = entry.HookStateData;

        if (key === '0000000000000000000000000000000000000000000000000000000000004D43') {
          layer2Data.memberCount = parseInt(value, 16);
        } else if (key.startsWith('00000000000000000000000000000000000000000000000000000000000000')) {
          const seatNumber = parseInt(key.slice(-2), 16);
          const accountId = decodeAccountId(value);
          layer2Data.seats[seatNumber] = accountId;
        }
      }

      return layer2Data;
    } else {
      return null;
    }
  } catch (error) {
    // console.error('Error fetching Layer 2 data:', error);
    return null;
  }
};
