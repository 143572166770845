import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  Filler
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { format } from 'date-fns';
import './PriceChart.css';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  Filler
);

const TimeRanges = {
  '24H': { scale: '1h', limit: 24 },
  '7D': { scale: '4h', limit: 42 },
  '30D': { scale: '1d', limit: 30 }
};

const PriceChart = ({ tokenSymbol = 'XAH' }) => {
  const [priceData, setPriceData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRange, setSelectedRange] = useState('24H');
  const [priceStats, setPriceStats] = useState({ high: 0, low: 0, change: 0 });
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState(null);

  const calculatePriceStats = (data) => {
    if (data.length === 0) return { high: 0, low: 0, change: 0 };
    
    const prices = data.map(d => d.y);
    const high = Math.max(...prices);
    const low = Math.min(...prices);
    const startPrice = prices[0];
    const endPrice = prices[prices.length - 1];
    const change = ((endPrice - startPrice) / startPrice) * 100;
    
    return { high, low, change };
  };

  useEffect(() => {
    const fetchPrice = async () => {
      try {
        setIsLoading(true);
        const symbolMap = {
          'XAH': 'XAHUSDT',
          'XRP': 'XRPUSDT',
          'EVR': 'EVRUSDT',
          'FLR': 'FLRUSDT'
        };

        const symbol = symbolMap[tokenSymbol];
        if (!symbol) {
          throw new Error('Unsupported token symbol');
        }

        const { scale, limit } = TimeRanges[selectedRange];
        const response = await fetch(`https://openapi.bitrue.com/api/v1/market/kline?symbol=${symbol}&scale=${scale}&limit=${limit}`);

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.msg || `HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        
        if (data.data && Array.isArray(data.data)) {
          const formattedData = data.data.map(candle => ({
            x: new Date(candle.is).getTime(),
            y: parseFloat(candle.c)
          }));
          setPriceData(formattedData);
          setPriceStats(calculatePriceStats(formattedData));
        } else {
          throw new Error('Invalid data format received from API');
        }
        
        setIsLoading(false);
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    };

    fetchPrice();
    const interval = setInterval(fetchPrice, 60000); // Update every minute

    return () => clearInterval(interval);
  }, [tokenSymbol, selectedRange]);

  const createGradient = (ctx) => {
    if (!ctx) return null;
    const gradient = ctx.createLinearGradient(0, 0, 0, 300);
    const color = priceStats.change >= 0 ? '0, 255, 0' : '255, 0, 0';
    gradient.addColorStop(0, `rgba(${color}, 0.5)`);
    gradient.addColorStop(1, `rgba(${color}, 0)`);
    return gradient;
  };

  const createChartData = useCallback(() => {
    if (!priceData.length) return null;
    const ctx = chartRef.current?.ctx;
    return {
      datasets: [
        {
          data: priceData,
          borderColor: priceStats.change >= 0 ? '#00ff00' : '#ff0000',
          backgroundColor: ctx ? createGradient(ctx) : 'rgba(97, 218, 251, 0.1)',
          fill: true,
          tension: 0.4,
          borderWidth: 2,
          pointRadius: 0,
          pointHoverRadius: 4,
        }
      ]
    };
  }, [priceData, priceStats.change]);

  // Update chart data when price data or stats change
  useEffect(() => {
    if (chartRef.current?.ctx) {
      setChartData(createChartData());
    }
  }, [priceData, priceStats, createChartData]);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 4,
        right: 4,
        top: 4,
        bottom: 16
      }
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: selectedRange === '24H' ? 'hour' : selectedRange === '7D' ? 'day' : 'week',
          displayFormats: {
            hour: 'HH:mm',
            day: 'MMM d',
            week: 'MMM d'
          }
        },
        grid: {
          display: false
        },
        ticks: {
          maxRotation: 0,
          minRotation: 0,
          padding: 8,
          autoSkipPadding: 30,
          maxTicksLimit: selectedRange === '30D' ? 8 : 6,
          color: 'rgba(255, 255, 255, 0.8)',
          font: {
            size: 12,
            weight: '500'
          }
        }
      },
      y: {
        position: 'right',
        grid: {
          color: 'rgba(255, 255, 255, 0.1)'
        },
        ticks: {
          callback: function(value) {
            return '$' + value.toFixed(4);
          },
          padding: 8,
          maxTicksLimit: 5,
          color: 'rgba(255, 255, 255, 0.8)',
          font: {
            size: 12,
            weight: '500'
          }
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: true,
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        padding: 6,
        cornerRadius: 4,
        callbacks: {
          title: (context) => format(new Date(context[0].parsed.x), 'MMM d, yyyy HH:mm'),
          label: (context) => `$${context.parsed.y.toFixed(4)}`
        },
        titleFont: {
          size: 11
        },
        bodyFont: {
          size: 11,
          weight: 'bold'
        }
      }
    },
    interaction: {
      intersect: false,
      mode: 'index'
    }
  };

  return (
    <div className="price-chart-container">
      <div className="chart-wrapper">
        {isLoading ? (
          <div className="loading-skeleton">
            <div className="price-skeleton"></div>
            <div className="chart-skeleton"></div>
          </div>
        ) : error ? (
          <div className="error">
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="12" y1="8" x2="12" y2="12"></line>
              <line x1="12" y1="16" x2="12" y2="16"></line>
            </svg>
            <div>{error}</div>
          </div>
        ) : (
          <>
            <div className="time-range-selector">
              {Object.keys(TimeRanges).map((range) => (
                <button
                  key={range}
                  className={`time-range-button ${selectedRange === range ? 'active' : ''}`}
                  onClick={() => setSelectedRange(range)}
                >
                  {range}
                </button>
              ))}
            </div>
            <div className="price-stats">
              <div className="stat-item">
                <div className="stat-label">24h High</div>
                <div className="stat-value">${priceStats.high.toFixed(4)}</div>
              </div>
              <div className="stat-item">
                <div className="token-name">{tokenSymbol}</div>
                <div className="stat-label">Current</div>
                <div className={`stat-value ${priceStats.change >= 0 ? 'positive' : 'negative'}`}>
                  ${priceData[priceData.length - 1]?.y.toFixed(4)}
                  <span className="change-percentage">
                    ({priceStats.change >= 0 ? '+' : ''}{priceStats.change.toFixed(2)}%)
                  </span>
                </div>
              </div>
              <div className="stat-item">
                <div className="stat-label">24h Low</div>
                <div className="stat-value">${priceStats.low.toFixed(4)}</div>
              </div>
            </div>
            <div className="chart-container">
              <Line ref={chartRef} options={chartOptions} data={chartData || createChartData()} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PriceChart;