import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <img src="/tables_seats/xamini.png" alt="Xamini" className="footer-logo-img" />
        </div>
        {/* 
        <nav className="footer-nav">
          <Link to="/">Governance</Link>
          <Link to="/validators">Validators</Link>
          <Link to="/account">Storyboard</Link>
          <Link to="/ecosystem">Ecosystem</Link>
          <Link to="/events">Events</Link>
          <Link to="/governance">Governance</Link>
        </nav> 
        */}
        <div className="footer-social">
          <h3>Connect With Us</h3>
          <div className="footer-social-icons">
            <a href="https://x.com/dazzlingxrpl" target="_blank" rel="noopener noreferrer">
              <img src="/x.svg" alt="X (Twitter)" className="footer-social-icon" />
            </a>
            <a href="https://github.com/dazzlingxrpl" target="_blank" rel="noopener noreferrer">
              <img src="/github.svg" alt="GitHub" className="footer-social-icon github" />
            </a>
          </div>
        </div>
        <div className="footer-ecosystem">
          <a href="https://xahau.network/" target="_blank" rel="noopener noreferrer">
            <img src="/xahau.svg" alt="Xahau" className="footer-ecosystem-icon" />
          </a>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Xamini. All rights reserved.</p>
        {/* <p><a href="/privacy-policy">Privacy Policy</a> | <a href="/terms-of-service">Terms of Service</a></p> */}
      </div>
    </footer>
  );
};

export default Footer;

