import React, { useRef, useState, useEffect, Suspense, useMemo, useCallback } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { OrbitControls, Html } from '@react-three/drei';
import './Globe.css';
import { feature } from 'topojson-client';
import worldAtlas from 'world-atlas/countries-110m.json';
import EventMenu from './EventMenu';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry';
import { gsap } from 'gsap';
import { Link } from 'react-router-dom';
const worldGeoJson = feature(worldAtlas, worldAtlas.objects.countries);

// Major cities data
const CITIES = [
  { 
    lat: 26.7153,
    lng: -80.0534,
    events: [{
      id: 1,
      name: "XRP Meetup Florida",
      city: "West Palm Beach",
      date: "February 28 - March 1, 2025",
      time: "17:00",
      address: "TBA",
      description: "Born from a community-driven initiative by Shellfish, this XRP meetup has quickly evolved into a highly anticipated gathering. The event will feature either a relaxed golf session or a brewery meetup, embodying the perfect blend of networking and leisure. This grassroots event showcases the strength and unity of the XRP community. For more details and to join the conversation, connect with Shellfish through the link below.",
      website: "https://x.com/XRPeaceOfMind",
      minPrice: "TBA",
      image: "/images/westpalmbeach.png"
    }]
  },
  { 
    lat: 36.1699,
    lng: -115.1398,
    events: [
      {
        id: 2,
        name: "XRP Las Vegas 2025",
        city: "Las Vegas",
        date: "May 30-31, 2025",
        time: "09:00",
        address: "MGM Grand Conference Center, 4701 Koval Ln, Las Vegas, NV 89109",
        description: "Discover the Future of Finance and Web3 at the World's Largest XRP Conference. Connect with the brightest minds in the industry, including global policy makers, leading experts, and trailblazing innovators shaping the future of decentralized finance. Gain exclusive insights, explore groundbreaking advancements, and stay ahead with the most accurate, up-to-date information in the ever-evolving world of Web3. \n\nRequirements: ID Verification, 16+ years old. No refunds available.",
        website: "https://xrplasvegas.com",
        minPrice: 507.98,
        image: "/images/vegas.png"
      },
      {
        id: 8,
        name: "Rare EVO 2025",
        city: "Las Vegas",
        date: "August 6-10, 2025",
        time: "TBA",
        address: "Caesars Palace Las Vegas, NV",
        description: "Rare Evo is a blockchain convention held August 6-10th, 2025. We welcome multi-chain projects, communities, industry leaders, investors, and enthusiasts to network, educate, and celebrate around the theme of interoperability and the convergence of traditional industry with Web3 technology. Our audience and programming cover all verticles of the space alongside an inclusive blockchain agnostic approach. The event offers a variety of networking opportunities and entertainment options daily and nightly through the course of a long weekend.",
        website: "https://rareevo.io/index.php?route=common/home",
        minPrice: 500,
        image: "/images/rareevo.png"
      }
    ]
  },
  { 
    lat: 1.3521,
    lng: 103.8198,
    events: [{
      id: 3,
      name: "XRP Apex 2025",
      city: "Singapore",
      date: "June 10-12, 2025",
      time: "TBA",
      address: "TBA",
      description: "XRP Ledger Apex, hosted by Ripple is the largest annual summit on the XRPL calendar. It unites developers, businesses, fintechs, researchers, VCs and the wider community. Be the first to hear about major updates, explore unmatched networking opportunities and engage directly with key players shaping the future of XRP Ledger ecosystem.",
      website: "https://www.xrpledgerapex.com",
      minPrice: "TBA",
      image: "/images/apex.png"
    }]
  },
  { 
    lat: -27.4705,
    lng: 153.0260,
    events: [
      {
        id: 7,
        name: "XRP Brisbane Meetup",
        city: "Brisbane",
        date: "March 28-29, 2025",
        time: "TBA",
        address: "TBA",
        description: "Crypto queen is planning an XRP meet up in Brisbane, for more details reach out to her via the link below.",
        website: "https://x.com/CryptoQueenAU",
        minPrice: "TBA",
        image: "/images/brisbane.png"
      },
      {
        id: 4,
        name: "Wave Of Innovation 2025",
        city: "TBA",
        date: "TBA 2025",
        time: "TBA",
        address: "TBA",
        description: "More details coming soon.",
        website: "https://www.waveofinnovation.com",
        minPrice: "TBA",
        image: "/images/WOI.png"
      }
    ]
  },
  { 
    lat: 48.8620,  // Paris coordinates
    lng: 2.3539,
    events: [
      {
        id: 11,
        name: "XRPL Community Magazine #4 Launch Executive Breakfast",
        city: "Paris",
        date: "February 5, 2025",
        time: "08:30 - 11:00 CET",
        address: "84 Rue Beaubourg, 75003 Paris, France",
        description: "We're excited to launch the fourth volume of the XRPL Community Magazine! Join us for an executive breakfast and be among the first to receive the latest edition. Hosted by XRPL Commons with 264 followers and 2.6k previous attendees, this event provides a perfect opportunity to network with fellow XRPL enthusiasts while enjoying breakfast.",
        website: "https://www.xrpl-commons.org",
        minPrice: "Free",
        image: "/images/commonsbreakfast.png"
      }
    ]
  }
];

// Convert lat/lng to 3D coordinates
const latLngToVector3 = (lat, lng, radius) => {
  // Convert to radians
  const phi = (90 - lat) * (Math.PI / 180);
  const theta = (lng + 180) * (Math.PI / 180);

  // Calculate position
  const x = -(radius * Math.sin(phi) * Math.cos(theta));
  const y = radius * Math.cos(phi);
  const z = radius * Math.sin(phi) * Math.sin(theta);

  return new THREE.Vector3(x, y, z);
};

const CityMarker = ({ position, city, onClick, isSelected, onClickOutside }) => {
  const [hovered, setHovered] = useState(false);
  const markerRef = useRef();
  const popupRef = useRef();

  // Modified click outside handler
  useEffect(() => {
    const handleClick = (event) => {
      if (!isSelected) return;
      
      // Only proceed if popupRef is available
      if (!popupRef.current) return;

      // Check if click is on popup
      if (!popupRef.current.contains(event.target)) {
        onClickOutside();
      }
    };

    window.addEventListener('click', handleClick);
    return () => window.removeEventListener('click', handleClick);
  }, [isSelected, onClickOutside]);

  useFrame(() => {
    if (!markerRef.current) return;
    
    const targetScale = hovered ? 1.5 : 1;
    markerRef.current.scale.x = THREE.MathUtils.lerp(markerRef.current.scale.x, targetScale, 0.1);
    markerRef.current.scale.y = THREE.MathUtils.lerp(markerRef.current.scale.y, targetScale, 0.1);
    markerRef.current.scale.z = THREE.MathUtils.lerp(markerRef.current.scale.z, targetScale, 0.1);
  });

  return (
    <group position={position}>
      <mesh 
        ref={markerRef}
        onClick={(e) => {
          e.stopPropagation();
          onClick();
        }}
        onPointerOver={(e) => {
          e.stopPropagation();
          setHovered(true);
        }}
        onPointerOut={(e) => {
          e.stopPropagation();
          setHovered(false);
        }}
      >
        <sphereGeometry args={[0.05, 16, 16]} />
        <meshBasicMaterial 
          color={isSelected ? "#ff0000" : (hovered ? "#ffffff" : "#61dafb")}
          transparent
          opacity={0.8}
        />
      </mesh>
      {(isSelected || hovered) && (
        <Html distanceFactor={10}>
          <div 
            ref={popupRef}
            onClick={(e) => e.stopPropagation()}
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              color: 'white',
              padding: isSelected ? '10px' : '4px 8px',
              borderRadius: '5px',
              width: isSelected ? '200px' : 'auto',
              boxShadow: '0 0 10px rgba(0,0,0,0.5)',
              transform: 'translate3d(-50%, -50%, 0)',
              pointerEvents: isSelected ? 'auto' : 'none',
              cursor: 'default',
            }}
          >
            {isSelected ? (
              <>
                <h3 style={{ margin: '0 0 8px 0' }}>{city.name}</h3>
                <p style={{ margin: '4px 0' }}>Population: {city.population}</p>
                <p style={{ margin: '4px 0' }}>{city.description}</p>
              </>
            ) : (
              <span style={{ fontSize: '12px' }}>{city.name}</span>
            )}
          </div>
        </Html>
      )}
    </group>
  );
};

const fragmentShader = `
  uniform sampler2D globeTexture;
  uniform vec3 outlineColor;
  uniform float time;
  varying vec2 vUv;
  varying vec3 vNormal;

  void main() {
    vec4 texColor = texture2D(globeTexture, vUv);
    float landMask = smoothstep(0.3, 0.31, texColor.r);
    
    // Darker base color
    vec3 baseColor = vec3(0.02, 0.03, 0.08); // Very dark blue
    
    // Enhanced edge shine
    float fresnel = pow(1.0 - dot(vNormal, vec3(0.0, 0.0, 1.0)), 3.0);
    vec3 shineColor = vec3(0.1, 0.2, 0.4); // Subtle blue shine
    
    // Add subtle pulse to the shine
    float pulse = sin(time * 0.5) * 0.15 + 0.85;
    
    // Combine colors with enhanced shine
    vec3 finalColor = mix(baseColor, baseColor + shineColor * fresnel * pulse, landMask);
    
    // Add stronger edge highlight
    float edge = pow(1.0 - dot(vNormal, vec3(0.0, 0.0, 1.0)), 4.0);
    finalColor += vec3(0.1, 0.2, 0.3) * edge * 0.8;
    
    // Add very subtle sparkle to edges
    float sparkle = fract(sin(dot(vUv + time * 0.1, vec2(12.9898,78.233))) * 43758.5453123);
    finalColor += vec3(0.1, 0.15, 0.2) * smoothstep(0.97, 1.0, sparkle) * edge;

    gl_FragColor = vec4(finalColor, 0.95); // Slight transparency
  }
`;

const vertexShader = `
  varying vec2 vUv;
  varying vec3 vNormal;
  varying vec3 vPosition;
  uniform sampler2D globeTexture;

  void main() {
    vUv = uv;
    vNormal = normalize(normalMatrix * normal);
    
    // Displace vertices based on elevation
    vec4 texColor = texture2D(globeTexture, uv);
    float elevation = (texColor.r + texColor.g + texColor.b) / 3.0;
    vec3 newPosition = position + normal * elevation * 0.15; // Adjust 0.15 for elevation intensity
    
    vPosition = newPosition;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(newPosition, 1.0);
  }
`;

// Separate component for the 3D globe content
const GlobeContent = ({ isRotating }) => {
  const globeRef = useRef();

  // Updated shader material with transparency
  const shaderMaterial = useMemo(() => {
    return new THREE.ShaderMaterial({
      uniforms: {
        globeTexture: { value: null },
        outlineColor: { value: new THREE.Color('#4286f4') },
        time: { value: 0 }
      },
      vertexShader,
      fragmentShader,
      transparent: true,
      depthWrite: true,
    });
  }, []);

  // Add isOnLand function
  const isOnLand = useCallback((lat, lng) => {
    if (!worldGeoJson || !worldGeoJson.features) {
      return false;
    }

    while (lng > 180) lng -= 360;
    while (lng < -180) lng += 360;

    const point = [lng, lat];
    
    const isPointInPolygon = (point, polygon) => {
      let inside = false;
      for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        const xi = polygon[i][0], yi = polygon[i][1];
        const xj = polygon[j][0], yj = polygon[j][1];
        
        const intersect = ((yi > point[1]) !== (yj > point[1]))
            && (point[0] < (xj - xi) * (point[1] - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
      }
      return inside;
    };

    for (const feature of worldGeoJson.features) {
      if (feature.geometry.type === 'Polygon') {
        if (isPointInPolygon(point, feature.geometry.coordinates[0])) {
          return true;
        }
      } else if (feature.geometry.type === 'MultiPolygon') {
        for (const polygon of feature.geometry.coordinates) {
          if (isPointInPolygon(point, polygon[0])) {
            return true;
          }
        }
      }
    }

    return false;
  }, []);

  const generatePoints = useCallback(() => {
    const points = [];
    
    // Set spacing to 1.7
    const latSpacing = 1.7;
    const lngSpacing = 1.7;
    
    for (let lat = -85; lat <= 85; lat += latSpacing) {
      for (let lng = -180; lng <= 180; lng += lngSpacing) {
        if (isOnLand(lat, lng)) {
          points.push([lat, lng, 0.5]);
        }
      }
    }

    // Add custom point for Barbados
    points.push([13.1500, -59.4833, 0.5]); // Barbados coordinates
    
    return points;
  }, [isOnLand]);

  const addData = useCallback((points) => {
    points.forEach(([lat, lng]) => {
      const phi = (90 - lat) * Math.PI / 180;
      const theta = (180 - lng) * Math.PI / 180;
      const radius = 2;

      const x = radius * Math.sin(phi) * Math.cos(theta);
      const y = radius * Math.cos(phi);
      const z = radius * Math.sin(phi) * Math.sin(theta);

      const geometry = new THREE.SphereGeometry(0.008, 8, 8);
      const material = new THREE.MeshBasicMaterial({
        color: new THREE.Color(
          0,                           // No red
          0.2 + Math.random() * 0.2,  // Dark green range
          0.3 + Math.random() * 0.2   // Dark blue range
        ),
        opacity: 0.7,
        transparent: true
      });

      const point = new THREE.Mesh(geometry, material);
      point.position.set(x, y, z);
      
      // Store position data for color animation
      point.userData = {
        initialY: y,
        phase: Math.random() * Math.PI * 2,
        originalPosition: { x, y, z },
        // Add color transition data
        colorPhase: Math.random() * Math.PI * 2
      };
      
      globeRef.current.add(point);
    });
  }, []);

  useEffect(() => {
    if (globeRef.current) {
      const points = generatePoints();
      addData(points);
    }
  }, [generatePoints, addData]);

  useFrame(({ clock }) => {
    if (globeRef.current && isRotating) {
      globeRef.current.rotation.y += 0.001;
    }
    
    const time = clock.getElapsedTime();
    
    globeRef.current.children.forEach(child => {
      if (child.userData.originalPosition) {
        const posPhase = child.userData.phase;
        child.position.y = child.userData.initialY + Math.sin(time * 0.5 + posPhase) * 0.002;
        
        const { x, y, z } = child.userData.originalPosition;
        const colorPhase = child.userData.colorPhase;
        
        const wave1 = Math.sin(time * 2.0 + x + z + colorPhase);
        const wave2 = Math.cos(time * 1.6 - y + colorPhase);
        const wave3 = Math.sin(time * 1.8 + (x * z) + colorPhase);
        const wave4 = Math.cos(time * 1.4 + y + x + colorPhase);
        
        // Darker cyberpunk color palette
        const colors = [
          { r: 0.0, g: 0.2, b: 0.4 },    // Dark Blue
          { r: 0.0, g: 0.3, b: 0.3 },    // Dark Teal
          { r: 0.0, g: 0.4, b: 0.2 },    // Dark Green
          { r: 0.1, g: 0.2, b: 0.3 },    // Navy Blue
          { r: 0.0, g: 0.3, b: 0.4 },    // Deep Ocean
          { r: 0.1, g: 0.3, b: 0.2 },    // Forest Digital
          { r: 0.0, g: 0.2, b: 0.3 },    // Midnight Digital
          { r: 0.1, g: 0.4, b: 0.3 }     // Dark Matrix
        ];
        
        const colorIndex = Math.floor((wave1 + 1) * 3.99) % colors.length;
        const nextColorIndex = (colorIndex + 1) % colors.length;
        const mixFactor = ((wave1 + 1) * 4) % 1;
        
        const currentColor = colors[colorIndex];
        const nextColor = colors[nextColorIndex];
        
        // Occasional bright sparkle for contrast
        const sparkle = Math.pow(Math.sin(time * 3 + wave4), 12) * 0.4;
        
        if (child.material) {
          // Mix colors with occasional bright highlights
          const r = Math.min(1, currentColor.r * (1 - mixFactor) + nextColor.r * mixFactor + sparkle);
          const g = Math.min(1, currentColor.g * (1 - mixFactor) + nextColor.g * mixFactor + sparkle);
          const b = Math.min(1, currentColor.b * (1 - mixFactor) + nextColor.b * mixFactor + sparkle);
          
          child.material.color.setRGB(r, g, b);
          
          // Lower base opacity for darker feel
          child.material.opacity = 0.7 + 0.3 * Math.pow(Math.sin(time * 2.5 + colorPhase), 2);
        }
      }
    });

    if (shaderMaterial) {
      shaderMaterial.uniforms.time.value = clock.getElapsedTime();
    }
  });

  return (
    <group ref={globeRef}>
      <mesh>
        <sphereGeometry args={[2, 64, 64]} />
        <primitive object={shaderMaterial} attach="material" />
      </mesh>
    </group>
  );
};

const GlobeScene = ({ selectedEvent, onEventClose }) => {
  const { camera } = useThree();
  const controlsRef = useRef();
  const globeRef = useRef();
  const [locationIndicator, setLocationIndicator] = useState(null);
  const [isRotating, setIsRotating] = useState(true);
  const [beacons, setBeacons] = useState([]);

  const defaultBeaconColor = new THREE.Color(0xff3333); // Default red color
  const activeBeaconColor = new THREE.Color(0x4CAF50); // Green color for active state

  const dotMaterial = new THREE.MeshBasicMaterial({
    color: defaultBeaconColor,  // Changed from 0xff3333
    transparent: true,
    opacity: 0.9
  });

  const createLocationBeacons = useCallback(() => {
    if (!globeRef.current) return;

    const dots = globeRef.current.children[0].children;
    const allBeacons = [];
    const usedDots = new Map(); // Track which dots already have beacons

    const coordDistance = (lat1, lng1, lat2, lng2) => {
      return Math.sqrt(Math.pow(lat1 - lat2, 2) + Math.pow(lng1 - lng2, 2));
    };

    CITIES.forEach(city => {
      let closestDot = null;
      let minDistance = Infinity;

      dots.forEach(dot => {
        if (dot.type === 'Mesh') {
          const vector = dot.position.clone().normalize();
          const lat = 90 - (Math.acos(vector.y) * 180 / Math.PI);
          const lng = (Math.atan2(vector.z, -vector.x) * 180 / Math.PI);
          
          const distance = coordDistance(city.lat, city.lng, lat, lng);
          if (distance < minDistance) {
            minDistance = distance;
            closestDot = dot;
          }
        }
      });

      if (closestDot) {
        // Generate unique key for this dot
        const dotKey = `${closestDot.position.x},${closestDot.position.y},${closestDot.position.z}`;
        
        // If this dot already has a beacon, add these events to it instead of creating a new beacon
        if (usedDots.has(dotKey)) {
          const existingBeacon = usedDots.get(dotKey);
          existingBeacon.city.events.push(...city.events);
          return;
        }

        // Create new beacon
        const dotGeometry = new THREE.SphereGeometry(0.009, 8, 8);
        const dotMaterial = new THREE.MeshBasicMaterial({
          color: defaultBeaconColor,
          transparent: true,
          opacity: 0.9
        });
        const centerDot = new THREE.Mesh(dotGeometry, dotMaterial);
        closestDot.add(centerDot);
        
        const rings = [];
        for (let i = 0; i < 2; i++) {
          const ringGeometry = new THREE.RingGeometry(0.04, 0.045, 32);
          const ringMaterial = new THREE.MeshBasicMaterial({
            color: defaultBeaconColor,
            transparent: true,
            opacity: 0.8,
            side: THREE.DoubleSide
          });
          
          const ring = new THREE.Mesh(ringGeometry, ringMaterial);
          closestDot.add(ring);
          rings.push({ mesh: ring, material: ringMaterial });
          
          ring.position.copy(closestDot.position.clone().normalize().multiplyScalar(0.009));
          ring.lookAt(new THREE.Vector3(0, 0, 0));
          
          gsap.to(ring.scale, {
            x: 1.7,
            y: 1.7,
            z: 1.7,
            duration: 1.5,
            repeat: -1,
            delay: i * 0.75,
            ease: "none"
          });
          
          gsap.to(ringMaterial, {
            opacity: 0,
            duration: 1.5,
            repeat: -1,
            delay: i * 0.75,
            ease: "none"
          });
        }
        
        centerDot.position.copy(closestDot.position.clone().normalize().multiplyScalar(0.009));
        
        gsap.to(dotMaterial, {
          opacity: 0.4,
          duration: 1,
          repeat: -1,
          yoyo: true,
          ease: "sine.inOut"
        });
        
        const updateBeaconColors = (isActive) => {
          const color = isActive ? activeBeaconColor : defaultBeaconColor;
          dotMaterial.color.copy(color);
          rings.forEach(({ material }) => material.color.copy(color));
        };
        
        const beacon = {
          dot: centerDot,
          rings: rings,
          parentDot: closestDot,
          city: { ...city },  // Clone the city object
          updateColors: updateBeaconColors
        };
        
        allBeacons.push(beacon);
        usedDots.set(dotKey, beacon);  // Track this dot as used
      }
    });
    
    setBeacons(allBeacons);
    return allBeacons;
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (globeRef.current) {
        createLocationBeacons();
      }
    }, 100);

    return () => {
      clearTimeout(timer);
      beacons.forEach(beacon => {
        if (beacon.mesh && beacon.parentDot) {
          beacon.parentDot.remove(beacon.mesh);
        }
      });
    };
  }, [createLocationBeacons]);

  useEffect(() => {
    document.body.classList.add('globe-page');
    return () => {
      document.body.classList.remove('globe-page');
    };
  }, []);

  // Add this new useEffect at the top level:
  useEffect(() => {
    if (!controlsRef.current) return;

    // Check if we're in default view
    const isDefaultView = (
      Math.abs(camera.position.x) < 0.1 && 
      Math.abs(camera.position.y) < 0.1 && 
      Math.abs(camera.position.z - 8) < 0.1 &&
      Math.abs(controlsRef.current.target.x) < 0.1 &&
      Math.abs(controlsRef.current.target.y) < 0.1 &&
      Math.abs(controlsRef.current.target.z) < 0.1
    );

    if (selectedEvent || !isDefaultView) {
      // Disable all movement when event selected or not in default view
      controlsRef.current.autoRotate = false;
      controlsRef.current.enableRotate = false;
      controlsRef.current.enableZoom = false;
      controlsRef.current.enablePan = false;
      setIsRotating(false);
    } else {
      // Enable rotation, zoom and auto-rotate in default view
      controlsRef.current.autoRotate = true;
      controlsRef.current.enableRotate = true;
      controlsRef.current.enableZoom = true;
      controlsRef.current.enablePan = false;
      setIsRotating(true);
    }
  }, [selectedEvent, camera.position.x, camera.position.y, camera.position.z]);

  // Update the camera animation useEffect
  useEffect(() => {
    if (!controlsRef.current) return;

    // Force stop rotation at the start of any camera movement
    if (selectedEvent) {
      controlsRef.current.autoRotate = false;
      setIsRotating(false);
    }

    if (selectedEvent && beacons.length > 0) {
      const beacon = beacons.find(b => 
        b.city.events.some(event => event.id === selectedEvent.id)
      );

      if (beacon && beacon.dot) {
        // Force stop rotation again before animation
        controlsRef.current.autoRotate = false;
        setIsRotating(false);

        const targetPosition = new THREE.Vector3();
        beacon.dot.getWorldPosition(targetPosition);

        const cameraDistance = 4;
        const finalCameraPosition = targetPosition.clone().normalize().multiplyScalar(cameraDistance);

        controlsRef.current.target.set(0, 0, 0);

        gsap.to(camera.position, {
          x: finalCameraPosition.x,
          y: finalCameraPosition.y,
          z: finalCameraPosition.z,
          duration: 1,
          ease: "power2.inOut",
          onStart: () => {
            // Force stop rotation at start of animation
            if (controlsRef.current) {
              controlsRef.current.autoRotate = false;
              setIsRotating(false);
            }
          }
        });

        gsap.to(controlsRef.current.target, {
          x: targetPosition.x,
          y: targetPosition.y,
          z: targetPosition.z,
          duration: 1,
          ease: "power2.inOut"
        });
      }
    } else {
      gsap.to(camera.position, {
        x: 0,
        y: 0,
        z: 8,
        duration: 1,
        ease: "power2.inOut"
      });

      gsap.to(controlsRef.current.target, {
        x: 0,
        y: 0,
        z: 0,
        duration: 1,
        ease: "power2.inOut",
        onComplete: () => {
          // Only enable rotation if explicitly returning to default view
          if (controlsRef.current && !selectedEvent) {
            controlsRef.current.autoRotate = true;
            setIsRotating(true);
          }
        }
      });
    }
  }, [selectedEvent, beacons, camera]);

  useEffect(() => {
    // First, create a map of locations to group events by exact coordinates
    const locationMap = new Map();
    beacons.forEach(beacon => {
      // Use precise coordinates as key to ensure exact matching
      const key = `${beacon.city.lat.toFixed(4)},${beacon.city.lng.toFixed(4)}`;
      if (!locationMap.has(key)) {
        locationMap.set(key, []);
      }
      locationMap.get(key).push(beacon);
    });

    beacons.forEach(beacon => {
      const locationKey = `${beacon.city.lat.toFixed(4)},${beacon.city.lng.toFixed(4)}`;
      const beaconsAtLocation = locationMap.get(locationKey);
      const isSelected = selectedEvent && 
        beacon.city.events.some(event => event.id === selectedEvent.id);
      
      // Check if this dot has multiple events
      const hasMultipleEvents = beaconsAtLocation.length > 1;
      
      if (selectedEvent) {
        // Get the coordinates of the selected event
        const selectedBeacon = beacons.find(b => 
          b.city.events.some(event => event.id === selectedEvent.id)
        );
        
        // Check if this beacon is at the exact same coordinates as the selected event
        const isAtSelectedLocation = selectedBeacon && 
          selectedBeacon.city.lat.toFixed(4) === beacon.city.lat.toFixed(4) && 
          selectedBeacon.city.lng.toFixed(4) === beacon.city.lng.toFixed(4);

        if (isAtSelectedLocation && hasMultipleEvents) {
          // If at same location as selected event and has multiple events,
          // only show the selected event's beacon
          beacon.dot.visible = isSelected;
          beacon.rings.forEach(({ mesh }) => mesh.visible = isSelected);
        } else if (!isAtSelectedLocation) {
          // If at a different location, show one beacon per location
          beacon.dot.visible = beacon === beaconsAtLocation[0];
          beacon.rings.forEach(({ mesh }) => mesh.visible = beacon === beaconsAtLocation[0]);
        }
      } else {
        // If no event is selected, show only one beacon per location
        beacon.dot.visible = beacon === beaconsAtLocation[0];
        beacon.rings.forEach(({ mesh }) => mesh.visible = beacon === beaconsAtLocation[0]);
      }
      
      // Update colors
      beacon.updateColors(isSelected);
    });
  }, [selectedEvent, beacons]);

  return (
    <>
      <group ref={globeRef}>
        <GlobeContent isRotating={!selectedEvent} />
      </group>
      
      <OrbitControls 
        ref={controlsRef}
        minDistance={3}
        maxDistance={20}
        enablePan={false}
        enableRotate={true}
        enableZoom={true}
        autoRotate={!selectedEvent}
        autoRotateSpeed={0.5}
      />
    </>
  );
};

// Main Globe component
const Globe = () => {
  const [selectedEvent, setSelectedEvent] = useState(null);

  // Add useEffect to ensure consistent background
  // useEffect(() => {
  //   const eventDetails = document.querySelector('.event-details');
  //   if (eventDetails) {
  //     eventDetails.style.background = 'rgb(13, 28, 40)';
  //   }
  // }, [selectedEvent]);

  const handleEventSelect = useCallback((event) => {
    if (selectedEvent?.id === event.id) return;
    setSelectedEvent(event);
    
    // Use a combination of requestAnimationFrame and setTimeout to ensure content is rendered
    requestAnimationFrame(() => {
      setTimeout(() => {
        const menuContent = document.querySelector('.static-right-menu.has-event .menu-content');
        if (menuContent) {
          // Force a reflow
          menuContent.style.scrollBehavior = 'auto';
          menuContent.scrollTop = 0;
          // Reset scroll behavior
          requestAnimationFrame(() => {
            menuContent.style.scrollBehavior = '';
          });
        }
      }, 0);
    });
  }, [selectedEvent]);

  const handleClickOutside = useCallback((e) => {
    // Check if click is outside the menu
    const menu = document.querySelector('.static-right-menu');
    if (menu && !menu.contains(e.target)) {
      setSelectedEvent(null);
    }
  }, []);

  useEffect(() => {
    // Add click listener to handle clicks outside menu
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  // Add this useEffect to handle scroll behavior
  useEffect(() => {
    const menuContent = document.querySelector('.static-right-menu .menu-content');
    
    const handleScroll = (e) => {
      if (!menuContent) return;
      
      const isAtBottom = menuContent.scrollHeight - menuContent.scrollTop <= menuContent.clientHeight + 1;
      const isAtTop = menuContent.scrollTop === 0;
      
      // If we're at the bottom and trying to scroll down, or at the top and trying to scroll up
      if ((isAtBottom && e.deltaY > 0) || (isAtTop && e.deltaY < 0)) {
        e.preventDefault();
      }
    };

    menuContent?.addEventListener('wheel', handleScroll, { passive: false });
    
    return () => {
      menuContent?.removeEventListener('wheel', handleScroll);
    };
  }, [selectedEvent]); // Re-add listener when selected event changes

  return (
    <div className="globe-container" style={{ 
      position: 'relative', 
      width: '100%', 
      height: '100%',
      overflow: 'hidden'
    }}>
      <Link to="/" className="back-to-xamini" style={{ background: 'rgb(13, 28, 40)' }}>
        Back to Xamini →
      </Link>

      <div className={`static-right-menu ${selectedEvent ? 'has-event' : ''}`} style={{
        overflowX: 'hidden'
      }}>
        <div className="menu-content" style={{
          overflowX: 'hidden'
        }}>
          {selectedEvent ? (
            <>
              <div className="event-banner">
                {selectedEvent && (
                  <img 
                    src={selectedEvent.image}
                    alt={selectedEvent.name}
                    onError={(e) => {
                      e.target.src = "https://source.unsplash.com/1200x400/?technology,conference";
                    }}
                  />
                )}
              </div>
              <div className="event-details" style={{ background: 'rgba(13, 28, 40, 0.3)' }}>
                <h2>{selectedEvent.name}</h2>
                <div className="event-info-grid">
                  <h3 className="section-header">Details</h3>
                  <div className="info-item">
                    <i className="fas fa-calendar"></i>
                    <div className="info-content">
                      <label>Date & Time</label>
                      <p>{selectedEvent.date}</p>
                      <p>{selectedEvent.time}</p>
                    </div>
                  </div>
                  
                  <div className="info-item">
                    <i className="fas fa-ticket-alt"></i>
                    <div className="info-content">
                      <label>Price</label>
                      <p>{isNaN(selectedEvent.minPrice) ? selectedEvent.minPrice : `From $${selectedEvent.minPrice}`}</p>
                    </div>
                  </div>
                  
                  <div className="info-item">
                    <i className="fas fa-map-marker-alt"></i>
                    <div className="info-content">
                      <label>Location</label>
                      <p>{selectedEvent.city}</p>
                      <p className="address">{selectedEvent.address}</p>
                    </div>
                  </div>
                </div>
                <div className="event-description" style={{ background: 'rgb(13, 28, 40)' }}>
                  <h3 className="section-header">Description</h3>
                  <p>{selectedEvent.description}</p>
                </div>
                <a 
                  href={selectedEvent.website} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="website-button-globe"
                  style={{ background: 'rgb(13, 28, 40)' }}
                >
                  Visit Website
                </a>
              </div>
            </>
          ) : (
            <div className="menu-section">
              {/* Empty when no event selected */}
            </div>
          )}
        </div>
      </div>

      <div className="globe-stars-container">
        {[...Array(15)].map((_, i) => (
          <div key={i} className="globe-star" />
        ))}
      </div>

      <div style={{ 
        position: 'relative', 
        zIndex: 1, 
        width: '100%', 
        height: '100%',
        display: 'block',
        background: 'transparent'
      }}>
        <ErrorBoundary>
          <Suspense fallback={<div>Loading...</div>}>
            <Canvas
              gl={{ 
                antialias: true,
                alpha: true,
                preserveDrawingBuffer: true
              }}
              camera={{ 
                position: [0, 0, 8], 
                fov: 45,
                near: 0.1,
                far: 1000
              }}
            >
              <GlobeScene 
                selectedEvent={selectedEvent}
                onEventClose={handleEventSelect}
              />
            </Canvas>
          </Suspense>
        </ErrorBoundary>
      </div>

      <EventMenu 
        events={CITIES.flatMap(city => 
          city.events.map(event => ({
            ...event,
            country: city.country || '',
            lat: city.lat,
            lng: city.lng
          }))
        )} 
        onEventSelect={handleEventSelect} 
        style={{ background: 'rgb(13, 28, 40)' }}
      />
    </div>
  );
};

// Error Boundary component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log('Error:', error);
    console.log('Error Info:', errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <div>Something went wrong with the 3D rendering.</div>;
    }

    return this.props.children;
  }
}

// Deprecated export - kept for backward compatibility
export const CameraController = () => {
  console.warn('CameraController is deprecated. OrbitControls are now integrated directly into the Globe component.');
  return (
    <OrbitControls 
      minDistance={3}
      maxDistance={20}
      enablePan={false}
    />
  );
};

export default Globe;