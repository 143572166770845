import React from 'react';
import { useNavigate } from 'react-router-dom';
import './SmartContractPage.css';

const SmartContractPage = ({ contracts }) => {
  const navigate = useNavigate();

  const handleContractClick = () => {
    navigate('/hooks'); // Navigate to HooksPage instead of contract-specific route
  };

  return (
    <div className="smart-contract-page">
      <h1>Educational Storyboard</h1>
      <div className="smart-contract-grid">
        {contracts.map(contract => (
          <div 
            key={contract.id} 
            className="smart-contract-item"
            onClick={handleContractClick} // Trigger navigation on click
          >
            <div className="smart-contract-content">
              <div 
                className="smart-contract-icon" 
                style={{ backgroundColor: contract.color }}
              >
                {contract.name.charAt(0)}
              </div>
              <h2>{contract.name}</h2>
              <p>{contract.description}</p>
              <div className="smart-contract-footer">
                <span className="price">{contract.price} XAH</span>
                <span className="view-details">View Details</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SmartContractPage;