import { decodeAccountId, decodeXFL } from './decoders';
import { toast } from 'react-toastify';

const API_URL = 'https://xahau.network/';

export const fetchAccountDetails = async (address) => {
  try {
    // Fetch account info
    const accountInfoResponse = await fetch(API_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        method: "account_info",
        params: [{
          account: address,
          ledger_index: "validated",
          strict: true
        }]
      })
    });

    const accountInfoData = await accountInfoResponse.json();

    // Fetch account lines (tokens)
    const accountLinesResponse = await fetch(API_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        method: "account_lines",
        params: [{
          account: address,
          ledger_index: "validated"
        }]
      })
    });

    const accountLinesData = await accountLinesResponse.json();

    // Fetch account transactions
    const accountTxResponse = await fetch(API_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        method: "account_tx",
        params: [{
          account: address,
          ledger_index_min: -1,
          ledger_index_max: -1,
          limit: 200,
          binary: false
        }]
      })
    });

    const accountTxData = await accountTxResponse.json();

    // Fetch account namespace
    const namespaceResponse = await fetch(API_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        method: "account_namespace",
        params: [{
          account: address,
          namespace_id: "0000000000000000000000000000000000000000000000000000000000000000",
          ledger_index: "validated"
        }]
      })
    });

    const namespaceData = await namespaceResponse.json();

    let hookStateData = {};

    if (namespaceData.result && namespaceData.result.namespace_entries) {
      for (const entry of namespaceData.result.namespace_entries) {
        const key = entry.HookStateKey;
        const value = entry.HookStateData;

        // Decode specific keys
        if (key === '0000000000000000000000000000000000000000000000000000000000004D43') {
          hookStateData.memberCount = parseInt(value, 16);
        } else if (key === '0000000000000000000000000000000000000000000000000000000000005252') {
          hookStateData.rewardRate = decodeXFL(value);
        } else if (key === '0000000000000000000000000000000000000000000000000000000000005244') {
          hookStateData.rewardDelay = decodeXFL(value);
        } else if (key.startsWith('00000000000000000000000000000000000000000000000000000000000000')) {
          const seatNumber = parseInt(key.slice(-2), 16);
          const accountId = decodeAccountId(value);
          if (!hookStateData.seats) hookStateData.seats = {};
          hookStateData.seats[seatNumber] = accountId;
        } else {
          // Store other keys as is
          hookStateData[key] = value;
        }
      }
    }

    // Declare genesisMintTransactions array
    const genesisMintTransactions = [];

    // Helper function to recursively search for Amount in GenesisMint
    const findGenesisMintAmount = (obj, address) => {
      if (typeof obj !== 'object' || obj === null) {
        return null;
      }
      
      if (obj.Destination === address && obj.Amount) {
        return obj.Amount;
      }
      
      for (let key in obj) {
        const result = findGenesisMintAmount(obj[key], address);
        if (result) return result;
      }
      
      return null;
    };

    // Fetch account objects
    const accountObjectsResponse = await fetch(API_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        method: "account_objects",
        params: [{
          account: address,
          ledger_index: "validated",
          type: "hook",
          deletion_blockers_only: false
        }]
      })
    });

    const accountObjectsData = await accountObjectsResponse.json();

    // Extract Hook objects
    const hookObjects = accountObjectsData.result.account_objects.filter(obj => obj.LedgerEntryType === 'Hook');

    // Fetch additional details for each Hook
    const hookDetailsPromises = hookObjects.flatMap(hookObj => 
      hookObj.Hooks.map(async hook => {
        const hookDetailResponse = await fetch(API_URL, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            method: "ledger_entry",
            params: [{
              hook_definition: hook.Hook.HookHash,
              ledger_index: "validated"
            }]
          })
        });
        return hookDetailResponse.json();
      })
    );

    const hookDetails = await Promise.all(hookDetailsPromises);

    // Process the data
    const accountDetails = {
      address: address,
      balance: new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 6,
        maximumFractionDigits: 6
      }).format(Number(accountInfoData.result.account_data.Balance) / 1000000),
      tokens: accountLinesData.result.lines.map(token => ({
        ...token,
        balance: new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 6,
          maximumFractionDigits: 6
        }).format(Number(token.balance))
      })),
      hookStateData: hookStateData,
      transactions: accountTxData.result.transactions.map(tx => {
        if (tx.tx && tx.tx.TransactionType === 'GenesisMint') {
          // Handle GenesisMint transaction
          const genesisMintAmount = findGenesisMintAmount(tx, address);
          
          if (genesisMintAmount) {
            const xahAmount = Number(genesisMintAmount) / 1000000;
            tx.tx.FormattedAmount = `${new Intl.NumberFormat('en-US', {
              minimumFractionDigits: 6,
              maximumFractionDigits: 6
            }).format(xahAmount)} XAH`;
          } else {
            tx.tx.FormattedAmount = '0.000000 XAH';
          }
          
          tx.tx.FlowType = 'in'; // GenesisMint is always incoming
          
          // Collect GenesisMint transaction info
          genesisMintTransactions.push({
            FormattedAmount: tx.tx.FormattedAmount,
            OriginalAmount: genesisMintAmount,
            Date: tx.tx.date,
            Hash: tx.tx.hash
          });
        } else if (tx.tx) {
          // Handle all other transaction types
          if (tx.tx.Amount) {
            let amount, currency;
            if (typeof tx.tx.Amount === 'string') {
              amount = tx.tx.Amount;
              currency = 'XAH';
            } else {
              amount = tx.tx.Amount.value || '0';
              currency = tx.tx.Amount.currency || 'XAH';
            }
            const formattedAmount = currency === 'XAH' 
              ? Number(amount) / 1000000
              : Number(amount);
            tx.tx.FormattedAmount = `${new Intl.NumberFormat('en-US', {
              minimumFractionDigits: 6,
              maximumFractionDigits: 6
            }).format(formattedAmount)} ${currency}`;
          }

          // Determine if it's an inflow, outflow, or inHouse transaction
          if (tx.tx.Account === address && tx.tx.Destination === address) {
            tx.tx.FlowType = 'inHouse';
          } else if (tx.tx.Account === address) {
            tx.tx.FlowType = 'out';
          } else if (tx.tx.Destination === address) {
            tx.tx.FlowType = 'in';
          } else {
            tx.tx.FlowType = 'inHouse'; // Default to inHouse if neither Account nor Destination match
          }
        }
        
        // If FlowType is still not set, default to 'inHouse'
        if (!tx.tx.FlowType) {
          tx.tx.FlowType = 'inHouse';
        }
        
        // Format the date
        if (tx.tx && tx.tx.date) {
          const rippleEpoch = 946684800;
          const unixTimestamp = (tx.tx.date + rippleEpoch) * 1000;
          const date = new Date(unixTimestamp);
          tx.tx.FormattedDate = date.toLocaleString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
          });
        }
        
        return tx;
      }),
      ledgerObjects: hookObjects,
      hookDetails: hookDetails.map(detail => detail.result.node)
    };

    return accountDetails;

  } catch (error) {
    console.error('Error fetching account details:', error);
    toast.error(
      <div>
        <span role="img" aria-label="error">❌</span> API Error: Please wait a few moments and try again.
      </div>,
      {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      }
    );
    return null;
  }
};
