import React, { useState } from 'react';
import WalletView from './WalletView';
import { layer1SeatLogos } from './SeatLogos';
import { fetchAccountDetails } from '../walletDetailsAPI';

// Object containing logos and names for each table
// The key represents the table number
const tableLogos = {
  0: { logo: '/tables_seats/xaman.png', name: 'Xaman' },
  1: { logo: '/tables_seats/titanium.png', name: 'Titanium' },
  2: { logo: '/tables_seats/evernode.png', name: 'Evernode' },
  3: { logo: '/tables_seats/digital_governing.png', name: 'Digital Governing' },
  4: { logo: '/tables_seats/gatehub.png', name: 'GateHub' },
  // Add more entries as needed, using null for logo if there isn't one
};

const Layer1Tables = ({ 
  rotatedPositions, // Array of positions for tables after rotation*******
  expandedTable, // Index of the currently expanded table
  activeWallet, // Currently active wallet
  handleTableClick, // Function to handle table clicks
  handleWalletClick: parentHandleWalletClick, // Function to handle wallet clicks
  expandedSeat, // Currently expanded seat******
  setActiveLogo, // Function to set the active logo
  showAllColored, // Boolean to determine if all tables should be colored
  setExpandedTableName, // Function to set the name of the expanded table
  governanceData, // Data about governance
  setSelectedAccountId, // Function to set the selected account ID *******
  setSelectedAccountBalance, // Function to set the selected account balance
  setIsLoading, // Function to set loading state
  isInteractionAllowed, // Boolean to determine if interaction is allowed
  setIsInteractionAllowed, // Function to set if interaction is allowed
  fetchAccountData, // Function to fetch account data
  setSelectedTransactions, // Function to set selected transactions
  setSelectedTokens, // Function to set selected tokens
  resetExpandedStates, // Function to reset expanded states
}) => {
  // State to keep track of which table is currently loading
  const [loadingTable, setLoadingTable] = useState(null);
  
  // Radius of the circle on which tables are positioned
  const tableRadius = 151.27;

  // Function to determine the color of a table
  const getTableColor = (tableNumber) => {
    if (showAllColored) {
      return 'colored';
    }
    return tableLogos.hasOwnProperty(tableNumber) ? 'colored' : 'grey';
  };

  const rotateIndex = (index) => (index + 5) % 20;

  // Function to handle table clicks
  const handleTableClickWrapper = async (type, index, event) => {
    if (!isInteractionAllowed || loadingTable !== null) return;
    
    setIsInteractionAllowed(false);
    setLoadingTable(index);
    
    const seatNumber = index;
    const tableId = `layer1-table-${seatNumber}`;

    // console.log(`Clicked table index: ${index}`);
    // console.log(`Seat number: ${seatNumber}`);
    // console.log(`Table ID: ${tableId}`);

    if (expandedTable === index) {
      // If clicking on an already expanded table, collapse it and reset states
      resetExpandedStates();
      setActiveLogo('/tables_seats/xahau.png');
      setExpandedTableName('Pick a Table or Seat');
      setSelectedAccountId('--');
      setSelectedAccountBalance('--');
      setSelectedTransactions([]);
      setSelectedTokens([]);
      setIsLoading(false);
    } else {
      // If expanding a new table
      handleTableClick(type, index, event);
      
      // Clear previous wallet data
      setSelectedAccountId('--');
      setSelectedAccountBalance('--');
      setSelectedTransactions([]);
      setSelectedTokens([]);

      setIsLoading(true);
      
      // Set logo and name for the expanded table
      const logoInfo = tableLogos[seatNumber] || { logo: null, name: 'Empty Seat' };
      setActiveLogo(logoInfo.logo || '/tables_seats/xahau.png');
      setExpandedTableName(logoInfo.name);

      // Fetch account data if available in governanceData
      if (governanceData && governanceData.layer1Tables) {
        const tableData = governanceData.layer1Tables[seatNumber];
        if (tableData && tableData.accountId) {
          try {
            const accountDetails = await fetchAccountData(tableData.accountId);
            if (accountDetails) {
              setSelectedAccountId(accountDetails.address || tableData.accountId);
              setSelectedAccountBalance(accountDetails.balance || '--');
              setSelectedTokens(accountDetails.tokens || []);
              setSelectedTransactions(accountDetails.transactions || []);
            } else {
              // console.log(`No account details found for table ${seatNumber}`);
            }
          } catch (error) {
            // console.error('Error fetching account details:', error);
          }
        } else {
          // console.log(`No account ID found for table ${seatNumber}`);
        }
      } else {
        // console.log('No governance data available');
      }

      setIsLoading(false);
    }
    
    // Reset loading state and allow interactions again
    setLoadingTable(null);
    setIsInteractionAllowed(true);
  };

  return (
    <>
      {/* Styles for fading tables in and out */}
      <style>
        {`
          .table-fade-out {
            opacity: 0;
            transition: opacity 0.7s ease;
          }

          .table-visible {
            opacity: 1;
            transition: opacity 0.7s ease;
          }
        `}
      </style>
      {/* Main group for all tables */}
      <g className={expandedSeat !== null ? 'table-fade-out' : 'table-visible'}>
        {[...Array(20)].map((_, i) => {
          const index = rotateIndex(i);
          const seatNumber = index;
          const tableId = `layer1-table-${seatNumber}`;
          const isTableExpanded = expandedTable === index;
          const isTableWalletActive = activeWallet && activeWallet.layer === 'table' && activeWallet.index === index;
          
          // Calculate position on the circle
          const angle = (i * 2 * Math.PI) / 20;
          const x = Math.cos(angle) * tableRadius;
          const y = Math.sin(angle) * tableRadius;

          // Get logo info for the table
          const logoInfo = tableLogos[seatNumber] || { logo: null, name: 'Vacant Table' };
          const logoSrc = logoInfo.logo || '/tables_seats/xahau.png';
          const displayName = logoInfo.name;
          const tableColor = getTableColor(seatNumber);

          return (
            <g 
              key={tableId}
              id={tableId}
              onClick={(e) => handleTableClickWrapper('table', index, e)}
              className={`layer1-group ${isTableExpanded ? 'expanded' : ''} ${isTableWalletActive ? 'wallet-active' : ''} ${tableColor} ${loadingTable === index ? 'loading' : ''}`}
              style={{
                '--translate-x': expandedSeat !== null ? `${x}px` : isTableExpanded ? '0px' : `${x}px`,
                '--translate-y': expandedSeat !== null ? `${y}px` : isTableExpanded ? '0px' : `${y}px`,
                '--scale': isTableExpanded ? 5.2 : 1,
                cursor: isInteractionAllowed && loadingTable === null ? 'pointer' : 'not-allowed',
              }}
            >
              {/* Circle representing the table */}
              <circle cx="0" cy="0" r="20" className={`layer1-seat table-${seatNumber} ${tableColor}`} />
              
              {/* Content of the table (number, logo or name) */}
              <g className={`seat-content ${isTableWalletActive ? 'hidden' : ''}`}>
                <text x="0" y="-7" textAnchor="middle" className="seat-text">{seatNumber}</text>
                {logoInfo.logo ? (
                  // Display logo if available
                  <image 
                    href={logoSrc}
                    x="-15" 
                    y="-15" 
                    width="30" 
                    height="30"
                    onError={(e) => {
                      // console.error('Error loading image:', e);
                      e.target.href = '/tables_seats/xahau.png';
                    }}
                  />
                ) : (
                  // Display name if no logo
                  displayName.length > 10 ? (
                    // Split long names into multiple lines
                    <>
                      {displayName.split(' ').reduce((acc, word) => {
                        const lastLine = acc[acc.length - 1];
                        if ((lastLine + ' ' + word).trim().length <= 15) {
                          acc[acc.length - 1] = lastLine + ' ' + word;
                        } else {
                          acc.push(word);
                        }
                        return acc;
                      }, ['']).map((line, index) => (
                        <text 
                          key={index}
                          x="0" 
                          y={0 + index * 3.5}
                          textAnchor="middle" 
                          className="seat-text no-logo"
                          style={{
                            fontSize: isTableExpanded ? '4px' : '4px',
                            fontWeight: 'bold',
                            transition: 'font-size 0.3s ease'
                          }}
                        >
                          {line.trim()}
                        </text>
                      ))}
                    </>
                  ) : (
                    // Display short names in a single line
                    <text 
                      x="0" 
                      y="0" 
                      textAnchor="middle" 
                      className="seat-text no-logo"
                      style={{
                        fontSize: isTableExpanded ? '5px' : '4px',
                        fontWeight: 'bold'
                      }}
                    >
                      {displayName}
                    </text>
                  )
                )}
                {/* "Layer 1 Table" text */}
                <text 
                  x="0" 
                  y="10" 
                  textAnchor="middle" 
                  className="seat-title"
                  style={{
                    fontSize: isTableExpanded ? '3.5px' : '2.5px',
                    fontWeight: 'bold',
                    transition: 'font-size 0.3s ease'
                  }}
                >
                  Layer 1 Table
                </text>
              </g>
              {/* Wallet view when table wallet is active */}
              {isTableWalletActive && (
                <g className="wallet-view">
                  {logoInfo.logo ? (
                    <image 
                      href={logoSrc}
                      x="-15" 
                      y="-18" 
                      width="30" 
                      height="30"
                      onError={(e) => {
                        // console.error('Error loading image:', e);
                        e.target.src = '/tables_seats/xahau.png';
                      }}
                    />
                  ) : (
                    <text 
                      x="0" 
                      y="0" 
                      textAnchor="middle" 
                      className="seat-text no-logo"
                      style={{
                        fontSize: displayName.length > 10 ? '3px' : '4px',
                        fontWeight: 'bold'
                      }}
                    >
                      {displayName}
                    </text>
                  )}
                  <WalletView 
                    walletAddress={`rTableWallet${index}`} 
                    balance="1000000" 
                    isInner={false}
                    logo={logoSrc}
                  />
                </g>
              )}
            </g>
          );
        })}
      </g>
    </>
  );
};

export default Layer1Tables;
