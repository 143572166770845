import React from 'react';
import './Events.css';

const Events = () => {
  return (
    <div className="events">
      <h1>Events</h1>
      <div className="earth-image-container">
        <img src="/earth-texture.svg" alt="Earth Texture" className="earth-image" />
      </div>
    </div>
  );
};

export default Events;