import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import StarryBackground from './components/StarryBackground';
import Header from './components/Header';
import CodeGenerator from './components/CodeGenerator';
import SmartContractPage from './components/SmartContractPage';
import ContractDetails from './components/ContractDetails';
import Home from './components/Home';
import GenericPage from './components/GenericPage';
import Footer from './components/Footer';
import Validators from './components/Validators';
import Ecosystem from './components/Ecosystem';
import Governance from './components/Governance';
import Events from './components/Events';
import HooksPage from './components/HooksPage';
import RulesOfEngagement from './components/RulesOfEngagement';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HookStore from './components/HookStore';

const smartContracts = [
  { id: 1, name: 'Hooks', description: 'Custom Rules for Transactions', price: '100', color: '#FF6B6B' },
  { id: 2, name: 'EVM', description: 'Platform for Running Smart Contracts', price: '200', color: '#4ECDC4' },
  { id: 3, name: 'DAO', description: 'Group Decision-Making Without Middlemen', price: '150', color: '#45B7D1' },
  { id: 4, name: 'Oracle', description: 'Bringing Outside Information to Blockchain', price: '180', color: '#F7B731' },
  { id: 5, name: 'AMM', description: 'Trading Without Traditional Exchanges', price: '250', color: '#5D5DFF' },
  { id: 6, name: 'Escrow', description: 'Holding Payments Until Conditions Are Met', price: '120', color: '#FF8C42' },
  { id: 7, name: 'Stablecoin', description: 'Cryptos Linked to Real-World Value', price: '130', color: '#98DDCA' },
  { id: 8, name: 'Tokenisation', description: 'Turning Assets into Digital Tokens', price: '110', color: '#D65DB1' },
  { id: 9, name: 'Interledger Protocol', description: 'Connecting Different Payment Networks', price: '160', color: '#FF9FF3' }
];

const pageContent = {
  staking: {
    title: "Staking",
    content: "This is the staking page. Here you can stake your tokens and earn rewards."
  },
  governance: {
    title: "Governance",
    content: "This is the governance page. Here you can participate in protocol decisions and vote on proposals."
  }
};

function App() {
  const [searchQuery, setSearchQuery] = useState('');
  const [resetSearch, setResetSearch] = useState(false);

  // Comment out the useEffect for logging state changes
  /*
  useEffect(() => {
    console.log('searchQuery:', searchQuery);
    console.log('resetSearch:', resetSearch);
  }, [searchQuery, resetSearch]);
  */

  const handleWalletSearch = (query) => {
    // console.log('handleWalletSearch called with query:', query);
    setSearchQuery(query);
    setResetSearch(false);
  };

  const handleSearchComplete = () => {
    // console.log('handleSearchComplete called');
    setResetSearch(true);
  };

  return (
    <Router>
      <div className="App">
        <StarryBackground />
        <Header onWalletSearch={handleWalletSearch} resetSearch={resetSearch} />
        <main className="main-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/governance" element={
              <Governance 
                searchQuery={searchQuery} 
                onSearchComplete={handleSearchComplete}
              />
            } />
            <Route path="/ecosystem" element={<Ecosystem />} />
            <Route path="/rules-of-engagement" element={<RulesOfEngagement />} />
          </Routes>
        </main>
        <Footer />
        <ToastContainer />
      </div>
    </Router>
  );
}

export default App;
