import React from 'react';
import './RulesOfEngagement.css'; // Import your CSS file for styling

const RulesOfEngagement = () => {
  return (
    <div className="rules-container">
      <h1 className="rules-title">Xahau Governance Game: Rules of Engagement</h1>
      
      <p className="beta-notice">
        <strong>Note:</strong> This is a beta version of Xamini, demonstrating a visual perspective of the Xahau Governance Game. If you encounter any bugs or issues, please reach out to us via DM on X at <a href="https://x.com/dazzlingxrpl" target="_blank" rel="noopener noreferrer">@dazzlingxrpl</a>. Your feedback is valuable in helping us improve the system.
      </p>

      <strong>Overview</strong>
      <p>
        The Xahau Governance Game is an innovative mechanism within the Xahau ecosystem that allows up to 400 stakeholders to participate democratically in managing the Xahau network. This is achieved through the Governance Hook installed on the Genesis Account.
      </p>

      <strong>Layer 1 Table</strong>
      <p>
        The game consists of a "Layer 1" table with 20 seats. Each seat can be filled by a Xahau account (r-address) or remain empty. Filled seats are considered "members" of the table. Members cast votes on three types of topics:
      </p>
      <ul>
        <li>Seat topics (S00-S19): Votes for who sits in each seat. An 80% majority is required for changes.</li>
        <li>Hook topics (H0-H9): Votes on which Hooks are installed on the table's account. 100% agreement is required.</li>
        <li>Reward topics (RR and RD): Votes on Reward-Rate and Reward-Delay, affecting the BalanceAdjustments system. 100% agreement is required.</li>
      </ul>

      <strong>Layer 2 Tables</strong>
      <p>
        Layer 1 seats can be occupied by Layer 2 tables, each consisting of another 20 seats. Layer 2 tables have the same voting structure for their own seats and hooks. Additionally, they can raise votes to the Layer 1 table with a 51% majority, counting as a single vote at Layer 1.
      </p>

      <strong>Game Constraints</strong>
      <ul>
        <li>Tables must have between 2 and 20 members.</li>
        <li>An r-address can occupy only one seat per table but can be on multiple tables.</li>
        <li>The game is currently limited to two layers.</li>
      </ul>

      <strong>Using Xamini Platform</strong>
      <p>
        Xamini is an educational platform designed to help users explore and understand the Xahau Governance Game. Users can:
      </p>
      <ul>
        <li>View Layer 1 and Layer 2 tables and their occupants</li>
        <li>Explore individual wallet details and transaction histories</li>
        <li>Learn about voting processes and game mechanics</li>
      </ul>

      <strong>Transaction Symbols and Emojis</strong>
      <p>
        In the transaction view, various symbols and emojis are used to represent different transaction types and flows:
      </p>
      <ul>
        <li>💳 Payment</li>
        <li>🪝 Invoke (Hook interaction)</li>
        <li>🎁 ClaimReward</li>
        <li>💰 GenesisMint</li>
        <li>🗳️ Remit</li>
        <li>🎟️ URITokenBuy</li>
        <li>🤝 TrustSet</li>
        <li>📊 OfferCreate</li>
        <li>🚫 OfferCancel</li>
        <li>📥 Import</li>
        <li>⚙️ AccountSet</li>
        <li>🔥 URITokenBurn</li>
        <li>↩ Incoming transaction (green)</li>
        <li>↪ Outgoing transaction (red)</li>
        <li>⌵ In-house transaction (grey)</li>
        <li>❓ Unknown transaction type</li>
        <li>🚧 More transaction types to come as we continue to build and expand Xamini</li>
      </ul>
      <p>
        As Xamini evolves, we'll be adding more transaction types and enhancing the visualization of the Xahau Governance Game. Stay tuned for updates!
      </p>

      <strong>Validator Rewards</strong>
      <p>
        Validators can earn rewards if they meet specific criteria, including being on the Xahau UNL and having their r-address seated at the L1 Table. Rewards are based on users' Balance Adjustments.
      </p>

      <strong>Disclaimer and Verification</strong>
      <p>
        Xamini is an educational platform and should not be considered as financial advice. Always verify information using official explorers:
      </p>
      <ul>
        <li><a href="https://xrplwin.com" target="_blank" rel="noopener noreferrer">xrplwin.com</a></li>
        <li><a href="https://xahscan.com" target="_blank" rel="noopener noreferrer">xahscan.com</a></li>
        <li><a href="https://xahexplorer.com" target="_blank" rel="noopener noreferrer">xahexplorer.com</a></li>
      </ul>
      <p>
        Xamini is not affiliated with any individual or entity associated with Xahau or its governance structure. The platform aims to provide an educational experience to help users understand the Xahau Governance Game.
      </p>

      <strong>Final Note</strong>
      <p>
        The Xahau Governance Game is a complex system designed to ensure community-centric decision-making. As you explore Xamini, remember that this is a learning tool, and actual participation in the governance process should be done with careful consideration and thorough understanding of the implications.
      </p>
    </div>
  );
};

export default RulesOfEngagement;
