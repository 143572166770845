export const layer1SeatLogos = {
    0: { logo: null, name: 'Projects Table' },
    1: { logo: '/tables_seats/xahau_dev_table.png', name: 'Xahau Dev Table' },
    3: { logo: null, name: 'Xahau Exchange Table' },
    

};

export const layer2SeatLogos = {
    //Projects Table
    '0-0': { logo: '/tables_seats/casinocoin.png', name: 'CasinoCoin' },
    '0-1': { logo: '/tables_seats/xspectar.png', name: 'Xspectar' },

    //Xahau Dev Table 
    '1-0': { logo: '/tables_seats/bithomp.png', name: 'Bithomp' },
    '1-1': { logo: null, name: 'Xahau Dev Table GENESIS' },
    '1-2': { logo: '/tables_seats/xrpscan.png', name: 'XRPScan' },
    '1-3': { logo: '/tables_seats/xrplwin.png', name: 'XRPLWin' },
    '1-4': { logo: '/tables_seats/tequ.png', name: 'Tequ' },
    '1-5': { logo: null, name: 'Xahau Dev Table GENESIS' },
    '1-6': { logo: null, name: 'Xahau Dev Table GENESIS' },
   

    //Xahau Exchange Table
    '3-0': { logo: '/tables_seats/bitrue.png', name: 'Bitrue' },
    '3-2': { logo: null, name: 'Xahau Exchange Table' }, 
     
};

export const getDefaultLogo = () => '/tables_seats/xahau.png';