export const languageExamples = {
  javascript: `
// JavaScript Example
// 1. Variables and Data Types
let name = "John";
const age = 30;
var isStudent = true;

// 2. Functions
function greet(name) {
  return \`Hello, \${name}!\`;
}

// 3. Arrow Functions
const multiply = (a, b) => a * b;

// 4. Arrays and Array Methods
let fruits = ['apple', 'banana', 'orange'];
fruits.push('grape');
let filteredFruits = fruits.filter(fruit => fruit.length > 5);

// 5. Objects
const person = {
  name: "Alice",
  age: 25,
  sayHello: function() {
    console.log("Hello!");
  }
};

// 6. Promises and Async/Await
async function fetchData() {
  try {
    let response = await fetch('https://api.example.com/data');
    let data = await response.json();
    console.log(data);
  } catch (error) {
    console.error('Error:', error);
  }
}

// 7. Modules
import { someFunction } from './someModule.js';
export const PI = 3.14159;
`,

  python: `
# Python Example
# 1. Variables and Data Types
name = "John"
age = 30
is_student = True

# 2. Functions
def greet(name):
    return f"Hello, {name}!"

# 3. Lambda Functions
multiply = lambda a, b: a * b

# 4. Lists and List Comprehensions
fruits = ['apple', 'banana', 'orange']
fruits.append('grape')
filtered_fruits = [fruit for fruit in fruits if len(fruit) > 5]

# 5. Dictionaries
person = {
    "name": "Alice",
    "age": 25,
    "say_hello": lambda: print("Hello!")
}

# 6. Exception Handling and File I/O
try:
    with open('example.txt', 'r') as file:
        content = file.read()
        print(content)
except FileNotFoundError:
    print("File not found")

# 7. Classes and Objects
class Dog:
    def __init__(self, name):
        self.name = name
    
    def bark(self):
        return f"{self.name} says Woof!"

# 8. Decorators
def uppercase_decorator(function):
    def wrapper():
        result = function()
        return result.upper()
    return wrapper

@uppercase_decorator
def greet():
    return "hello, world!"

# 9. Generators
def countdown(n):
    while n > 0:
        yield n
        n -= 1

# 10. Context Managers
from contextlib import contextmanager

@contextmanager
def file_manager(filename, mode):
    file = open(filename, mode)
    yield file
    file.close()
`,

  java: `
// Java Example
import java.util.*;

public class JavaExample {
    // 1. Variables and Data Types
    private String name = "John";
    private static final int AGE = 30;
    private boolean isStudent = true;

    // 2. Methods
    public String greet(String name) {
        return "Hello, " + name + "!";
    }

    // 3. Lambda Expressions (Java 8+)
    private interface Multiplier {
        int multiply(int a, int b);
    }
    Multiplier multiply = (a, b) -> a * b;

    // 4. Collections
    public void collectionExample() {
        List<String> fruits = new ArrayList<>(Arrays.asList("apple", "banana", "orange"));
        fruits.add("grape");
        List<String> filteredFruits = fruits.stream()
            .filter(fruit -> fruit.length() > 5)
            .collect(Collectors.toList());
    }

    // 5. Exception Handling
    public void exceptionExample() {
        try {
            // Some code that might throw an exception
            throw new Exception("This is an example exception");
        } catch (Exception e) {
            System.out.println("Caught exception: " + e.getMessage());
        } finally {
            System.out.println("This will always execute");
        }
    }

    // 6. Generics
    public <T> void printArray(T[] array) {
        for (T element : array) {
            System.out.println(element);
        }
    }

    // 7. Multithreading
    class MyThread extends Thread {
        public void run() {
            System.out.println("Thread is running");
        }
    }

    public static void main(String[] args) {
        JavaExample example = new JavaExample();
        System.out.println(example.greet("Alice"));
        
        MyThread thread = example.new MyThread();
        thread.start();
    }
}
`,

  cpp: `
// C++ Example
#include <iostream>
#include <vector>
#include <algorithm>
#include <memory>

// 1. Classes and Objects
class Dog {
private:
    std::string name;

public:
    Dog(std::string n) : name(n) {}
    void bark() { std::cout << name << " says Woof!" << std::endl; }
};

// 2. Function Templates
template <typename T>
T add(T a, T b) {
    return a + b;
}

// 3. Lambda Functions
auto multiply = [](int a, int b) { return a * b; };

int main() {
    // 4. Smart Pointers
    std::unique_ptr<Dog> dog = std::make_unique<Dog>("Buddy");
    dog->bark();

    // 5. Vectors and Algorithms
    std::vector<int> numbers = {1, 2, 3, 4, 5};
    std::for_each(numbers.begin(), numbers.end(), [](int n) {
        std::cout << n << " ";
    });
    std::cout << std::endl;

    // 6. Auto Keyword
    auto result = add(5, 10);
    std::cout << "5 + 10 = " << result << std::endl;

    // 7. Range-based for loop
    for (const auto& num : numbers) {
        std::cout << num << " ";
    }
    std::cout << std::endl;

    return 0;
}
`,

  rust: `
// Rust Example
use std::collections::HashMap;

// 1. Structs and Implementations
struct Dog {
    name: String,
}

impl Dog {
    fn new(name: &str) -> Dog {
        Dog { name: String::from(name) }
    }

    fn bark(&self) {
        println!("{} says Woof!", self.name);
    }
}

// 2. Traits
trait Animal {
    fn make_sound(&self);
}

impl Animal for Dog {
    fn make_sound(&self) {
        self.bark();
    }
}

// 3. Enums
enum Color {
    Red,
    Green,
    Blue,
}

// 4. Pattern Matching
fn print_color(color: Color) {
    match color {
        Color::Red => println!("Red"),
        Color::Green => println!("Green"),
        Color::Blue => println!("Blue"),
    }
}

fn main() {
    // 5. Variables and Mutability
    let immutable_var = 5;
    let mut mutable_var = 10;
    mutable_var += 5;

    // 6. Ownership and Borrowing
    let s1 = String::from("hello");
    let len = calculate_length(&s1);
    println!("The length of '{}' is {}.", s1, len);

    // 7. Error Handling
    let result = std::fs::read_to_string("nonexistent_file.txt");
    match result {
        Ok(content) => println!("File content: {}", content),
        Err(error) => println!("Error reading file: {}", error),
    }

    // 8. Closures
    let add = |a, b| a + b;
    println!("5 + 10 = {}", add(5, 10));

    // 9. Collections
    let mut scores = HashMap::new();
    scores.insert(String::from("Blue"), 10);
    scores.insert(String::from("Red"), 50);

    // 10. Iterators
    let numbers = vec![1, 2, 3, 4, 5];
    let sum: i32 = numbers.iter().sum();
    println!("Sum of numbers: {}", sum);
}

fn calculate_length(s: &String) -> usize {
    s.len()
}
`,

  php: `
<?php
// PHP Example

// 1. Variables and Data Types
$name = "John";
$age = 30;
$is_student = true;

// 2. Functions
function greet($name) {
    return "Hello, $name!";
}

// 3. Arrays
$fruits = array("apple", "banana", "orange");
$fruits[] = "grape";
$filtered_fruits = array_filter($fruits, function($fruit) {
    return strlen($fruit) > 5;
});

// 4. Associative Arrays
$person = array(
    "name" => "Alice",
    "age" => 25,
    "say_hello" => function() {
        echo "Hello!";
    }
);

// 5. Classes and Objects
class Dog {
    private $name;

    public function __construct($name) {
        $this->name = $name;
    }

    public function bark() {
        return $this->name . " says Woof!";
    }
}

$dog = new Dog("Buddy");
echo $dog->bark();

// 6. Error Handling
try {
    $file = fopen("nonexistent_file.txt", "r");
} catch (Exception $e) {
    echo "Error: " . $e->getMessage();
} finally {
    echo "This will always execute";
}

// 7. Superglobals
echo $_SERVER['HTTP_USER_AGENT'];
echo $_GET['param'];
echo $_POST['data'];

// 8. Include and Require
include 'header.php';
require 'footer.php';

// 9. Working with Databases (MySQL example)
$conn = new mysqli("localhost", "username", "password", "database");
if ($conn->connect_error) {
    die("Connection failed: " . $conn->connect_error);
}
$result = $conn->query("SELECT * FROM users");
$conn->close();

// 10. Sessions
session_start();
$_SESSION['user_id'] = 123;
?>
`,

  html: `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>HTML Example</title>
    <style>
        /* Internal CSS */
        body {
            font-family: Arial, sans-serif;
        }
    </style>
</head>
<body>
    <!-- 1. Headings -->
    <h1>Main Heading</h1>
    <h2>Subheading</h2>

    <!-- 2. Paragraphs -->
    <p>This is a paragraph of text.</p>

    <!-- 3. Links -->
    <a href="https://www.example.com">Visit Example.com</a>

    <!-- 4. Images -->
    <img src="image.jpg" alt="Description of the image">

    <!-- 5. Lists -->
    <ul>
        <li>Unordered list item 1</li>
        <li>Unordered list item 2</li>
    </ul>
    <ol>
        <li>Ordered list item 1</li>
        <li>Ordered list item 2</li>
    </ol>

    <!-- 6. Tables -->
    <table border="1">
        <tr>
            <th>Header 1</th>
            <th>Header 2</th>
        </tr>
        <tr>
            <td>Row 1, Cell 1</td>
            <td>Row 1, Cell 2</td>
        </tr>
    </table>

    <!-- 7. Forms -->
    <form action="/submit" method="post">
        <label for="name">Name:</label>
        <input type="text" id="name" name="name" required>
        <br>
        <label for="email">Email:</label>
        <input type="email" id="email" name="email" required>
        <br>
        <input type="submit" value="Submit">
    </form>

    <!-- 8. Semantic Elements -->
    <header>
        <nav>
            <ul>
                <li><a href="#home">Home</a></li>
                <li><a href="#about">About</a></li>
                <li><a href="#contact">Contact</a></li>
            </ul>
        </nav>
    </header>
    <main>
        <article>
            <h2>Article Title</h2>
            <p>Article content goes here.</p>
        </article>
    </main>
    <footer>
        <p>&copy; 2023 Your Company. All rights reserved.</p>
    </footer>

    <!-- 9. Audio and Video -->
    <audio controls>
        <source src="audio.mp3" type="audio/mpeg">
        Your browser does not support the audio element.
    </audio>
    <video width="320" height="240" controls>
        <source src="video.mp4" type="video/mp4">
        Your browser does not support the video tag.
    </video>

    <!-- 10. Inline JavaScript -->
    <script>
        console.log("Hello from inline JavaScript!");
    </script>
</body>
</html>
`,

  css: `
/* CSS Example */

/* 1. Selectors */
body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

#header {
    background-color: #f4f4f4;
    padding: 1rem;
}

/* 2. Box Model */
.box {
    width: 300px;
    padding: 20px;
    border: 1px solid #ccc;
    margin: 10px;
}

/* 3. Flexbox */
.flex-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* 4. Grid */
.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

/* 5. Positioning */
.relative {
    position: relative;
    top: 10px;
    left: 20px;
}

.absolute {
    position: absolute;
    top: 0;
    right: 0;
}

/* 6. Pseudo-classes and Pseudo-elements */
a:hover {
    color: #ff0000;
}

p::first-letter {
    font-size: 2em;
    font-weight: bold;
}

/* 7. Media Queries */
@media screen and (max-width: 768px) {
    .container {
        padding: 0 10px;
    }
}

/* 8. Animations */
@keyframes slide-in {
    from { transform: translateX(-100%); }
    to { transform: translateX(0); }
}

.animated {
    animation: slide-in 0.5s ease-out;
}

/* 9. Custom Properties (CSS Variables) */
:root {
    --primary-color: #007bff;
    --secondary-color: #6c757d;
}

.button {
    background-color: var(--primary-color);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
}

/* 10. Transforms */
.transformed {
    transform: rotate(45deg) scale(1.5);
}

/* 11. Transitions */
.transition {
    transition: all 0.3s ease-in-out;
}

.transition:hover {
    transform: scale(1.1);
}

/* 12. Gradients */
.gradient-background {
    background: linear-gradient(to right, #ff0000, #00ff00);
}

/* 13. Box Shadow */
.shadow-box {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* 14. Text Effects */
.text-shadow {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
`
};