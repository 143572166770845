import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ImageGallery from './ImageGallery';
import Card from './Card';
import PriceChart from './PriceChart';
import './Home.css';

const Home = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="home-container">
      <div className="home">
        <section className="whats-new-section">
          <h2 className="section-title">What's new on Xahau?</h2>
          <div className="cards-grid">
            <Card
              image="/images/coop.png"
              title="COOP Bank Partnership"
              description="InFTF partners with COOP Bank for remittances via Xahau"
              link="https://inftf.org/2024/10/03/coop-inftf-partnership-for-remittances/"
              isExternal={true}
              titleColor="#4CAF50"
            />
            <Card
              image="/images/docproof.png"
              title="Xahau DocProof"
              description="Document verification on the Xahau blockchain"
              link="https://xahaudocproof.com"
              isExternal={true}
              titleColor="#2196F3"
            />
            <Card
              image="/images/gov.png"
              title="Governance"
              description="Explore the Xahau governance system"
              link="/governance"
              titleColor="#2A9D8F"
            />
            <Card
              image="/images/xahreinvest.png"
              title="XAH Reinvest"
              description="DeFi investment platform on Xahau"
              link="https://xahinvest.com"
              isExternal={true}
              titleColor="#c1f732"
            />
            <Card
              image="/images/xahau_radio.png"
              title="Xahau Radio"
              description="Lo-Fi radio streaming with Xahau technology"
              link="https://xahauradio.com"
              titleColor="#fffd57"
            />
            <Card
              image="/images/vpra.jpeg"
              title="VPRA"
              description="NFT game built on the Xahau blockchain"
              link="https://vpra.app/home"
              isExternal={true}
              titleColor="#f40364"
            />
          </div>
        </section>

        <section className="hackathon-section" style={{
          padding: windowWidth <= 768 ? '0.75rem' : '1.5rem',
          margin: '1rem auto',
          maxWidth: '1200px',
          width: '95%',
          overflow: 'hidden'
        }}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: windowWidth <= 768 ? '0.6rem' : '1.2rem'
          }}>
            <div style={{
              textAlign: 'center',
              marginBottom: windowWidth <= 768 ? '0.3rem' : '0.8rem',
              position: 'relative',
              padding: '0 0.5rem'
            }}>
              <h2 style={{
                color: '#61dafb',
                fontSize: windowWidth <= 480 ? '1.1rem' : windowWidth <= 768 ? '1.3rem' : '2rem',
                margin: '0',
                textTransform: 'uppercase',
                letterSpacing: windowWidth <= 768 ? '0.5px' : '1.5px',
                textShadow: '0 0 10px rgba(97, 218, 251, 0.5)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: windowWidth <= 768 ? '6px' : '10px',
                flexWrap: 'wrap'
              }}>
                <span style={{ fontSize: windowWidth <= 480 ? '1.2rem' : windowWidth <= 768 ? '1.5rem' : '2.2rem' }}>🏆</span>
                <span style={{ wordBreak: 'break-word' }}>Build on Xahau Winners</span>
              </h2>
              <div style={{
                width: windowWidth <= 768 ? '95%' : '70%',
                height: '2px',
                background: 'linear-gradient(90deg, transparent, #61dafb, transparent)',
                margin: windowWidth <= 768 ? '0.4rem auto' : '0.8rem auto'
              }}/>
            </div>

            <div style={{
              display: 'grid',
              gridTemplateColumns: windowWidth <= 1024 ? '1fr' : '1.2fr 0.8fr',
              gap: windowWidth <= 768 ? '0.8rem' : '1.5rem',
              padding: windowWidth <= 480 ? '0.3rem' : '0.5rem'
            }}>
              {/* Winners Panel */}
              <div style={{
                background: 'rgba(97, 218, 251, 0.1)',
                borderRadius: '8px',
                padding: windowWidth <= 480 ? '0.8rem' : windowWidth <= 768 ? '1rem' : '1.2rem',
                border: '1px solid rgba(97, 218, 251, 0.2)',
                overflow: 'hidden'
              }}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: windowWidth <= 768 ? '0.6rem' : '0.8rem'
                }}>
                  {/* Gold Winner */}
                  <div style={{
                    background: 'linear-gradient(90deg, rgba(255, 215, 0, 0.1), transparent)',
                    padding: windowWidth <= 480 ? '0.6rem' : '0.8rem',
                    borderRadius: '6px',
                    border: '1px solid rgba(255, 215, 0, 0.3)',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.6rem',
                    flexWrap: 'nowrap'
                  }}>
                    <div style={{ fontSize: windowWidth <= 480 ? '1.4rem' : windowWidth <= 768 ? '1.6rem' : '1.8rem', flexShrink: 0 }}>🥇</div>
                    <div style={{ minWidth: 0 }}>
                      <h3 style={{ 
                        color: '#FFD700',
                        margin: '0',
                        fontSize: windowWidth <= 480 ? '1rem' : windowWidth <= 768 ? '1.1rem' : '1.2rem',
                        textShadow: '0 0 5px rgba(255, 215, 0, 0.3)',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}>Bridge POC</h3>
                      <p style={{ 
                        margin: '0.2rem 0 0 0',
                        color: '#888',
                        fontSize: windowWidth <= 480 ? '0.7rem' : '0.8rem'
                      }}>by ShortTheFOMO</p>
                      <p style={{ 
                        margin: '0.3rem 0 0 0',
                        color: '#4CAF50',
                        fontSize: windowWidth <= 480 ? '0.7rem' : '0.8rem',
                        fontWeight: 'bold'
                      }}>$2.5K USDT + 20K XAH</p>
                    </div>
                  </div>

                  {/* Silver Winner */}
                  <div style={{
                    background: 'linear-gradient(90deg, rgba(192, 192, 192, 0.1), transparent)',
                    padding: windowWidth <= 480 ? '0.6rem' : '0.8rem',
                    borderRadius: '6px',
                    border: '1px solid rgba(192, 192, 192, 0.3)',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.6rem',
                    flexWrap: 'nowrap'
                  }}>
                    <div style={{ fontSize: windowWidth <= 480 ? '1.4rem' : windowWidth <= 768 ? '1.6rem' : '1.8rem', flexShrink: 0 }}>🥈</div>
                    <div style={{ minWidth: 0 }}>
                      <h3 style={{ 
                        color: '#C0C0C0',
                        margin: '0',
                        fontSize: windowWidth <= 480 ? '1rem' : windowWidth <= 768 ? '1.1rem' : '1.2rem',
                        textShadow: '0 0 5px rgba(192, 192, 192, 0.3)',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}>Hooks in Rust</h3>
                      <p style={{ 
                        margin: '0.2rem 0 0 0',
                        color: '#888',
                        fontSize: windowWidth <= 480 ? '0.7rem' : '0.8rem'
                      }}>by 9oelM</p>
                      <p style={{ 
                        margin: '0.3rem 0 0 0',
                        color: '#4CAF50',
                        fontSize: windowWidth <= 480 ? '0.7rem' : '0.8rem',
                        fontWeight: 'bold'
                      }}>$1K USDT + 10K XAH</p>
                    </div>
                  </div>

                  {/* Bronze Winner */}
                  <div style={{
                    background: 'linear-gradient(90deg, rgba(205, 127, 50, 0.1), transparent)',
                    padding: windowWidth <= 480 ? '0.6rem' : '0.8rem',
                    borderRadius: '6px',
                    border: '1px solid rgba(205, 127, 50, 0.3)',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.6rem',
                    flexWrap: 'nowrap'
                  }}>
                    <div style={{ fontSize: windowWidth <= 480 ? '1.4rem' : windowWidth <= 768 ? '1.6rem' : '1.8rem', flexShrink: 0 }}>🥉</div>
                    <div style={{ minWidth: 0 }}>
                      <h3 style={{ 
                        color: '#CD7F32',
                        margin: '0',
                        fontSize: windowWidth <= 480 ? '1rem' : windowWidth <= 768 ? '1.1rem' : '1.2rem',
                        textShadow: '0 0 5px rgba(205, 127, 50, 0.3)',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}>JSHooks IDE</h3>
                      <p style={{ 
                        margin: '0.2rem 0 0 0',
                        color: '#888',
                        fontSize: windowWidth <= 480 ? '0.7rem' : '0.8rem'
                      }}>by xrplwin</p>
                      <p style={{ 
                        margin: '0.3rem 0 0 0',
                        color: '#4CAF50',
                        fontSize: windowWidth <= 480 ? '0.7rem' : '0.8rem',
                        fontWeight: 'bold'
                      }}>$500 USDT + 5K XAH</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* All Submissions Panel */}
              <div style={{
                background: 'rgba(97, 218, 251, 0.1)',
                borderRadius: '8px',
                padding: windowWidth <= 480 ? '0.8rem' : windowWidth <= 768 ? '1rem' : '1.2rem',
                border: '1px solid rgba(97, 218, 251, 0.2)',
                overflow: 'hidden'
              }}>
                <h3 style={{
                  color: '#61dafb',
                  margin: '0 0 0.8rem 0',
                  fontSize: windowWidth <= 480 ? '1rem' : windowWidth <= 768 ? '1.1rem' : '1.2rem',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '6px'
                }}>
                  <span style={{ fontSize: windowWidth <= 480 ? '1.1rem' : windowWidth <= 768 ? '1.2rem' : '1.3rem' }}>🌟</span>
                  All Submissions
                </h3>
                <div style={{
                  display: 'grid',
                  gridTemplateColumns: windowWidth <= 480 
                    ? 'repeat(auto-fit, minmax(100px, 1fr))'
                    : windowWidth <= 768 
                    ? 'repeat(auto-fit, minmax(130px, 1fr))'
                    : 'repeat(auto-fill, minmax(180px, 1fr))',
                  gap: windowWidth <= 480 ? '0.4rem' : '0.6rem'
                }}>
                  {[
                    'XahauRichlist by Evanoua',
                    'XAH Reinvest by defixahau',
                    'xMerch by mworks-proj',
                    'XRPL Loyalty by 0xPokotaro',
                    'URI Token by Cbot-XRPL',
                    'XWCode by zgrguric',
                    'XAHICO by nescampos',
                    'Xah_Elementals by sdoddler',
                    'Xai Protocol by JosepMariaVila',
                    'Trustline Auto Approver by elmurci'
                  ].map((project, index) => (
                    <div key={index} style={{
                      padding: windowWidth <= 480 ? '0.3rem 0.5rem' : '0.4rem 0.8rem',
                      background: 'rgba(97, 218, 251, 0.05)',
                      borderRadius: '6px',
                      border: '1px solid rgba(97, 218, 251, 0.1)',
                      color: '#fff',
                      fontSize: windowWidth <= 480 ? '0.7rem' : windowWidth <= 768 ? '0.75rem' : '0.8rem',
                      wordBreak: 'break-word',
                      overflow: 'hidden'
                    }}>
                      • {project}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div style={{
              textAlign: 'center',
              marginTop: windowWidth <= 768 ? '0.6rem' : '0.8rem',
              padding: '0 0.5rem'
            }}>
              <a 
                href="https://github.com/XRPL-Labs/Xahau-Dev-Contest/pulls" 
                target="_blank" 
                rel="noopener noreferrer" 
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: '6px',
                  background: 'rgba(97, 218, 251, 0.1)',
                  color: '#61dafb',
                  padding: windowWidth <= 480 ? '0.5rem 1rem' : windowWidth <= 768 ? '0.6rem 1.2rem' : '0.8rem 1.5rem',
                  borderRadius: '6px',
                  textDecoration: 'none',
                  fontWeight: 'bold',
                  fontSize: windowWidth <= 480 ? '0.7rem' : windowWidth <= 768 ? '0.8rem' : '0.9rem',
                  border: '1px solid rgba(97, 218, 251, 0.2)',
                  transition: 'all 0.3s ease',
                  textTransform: 'uppercase',
                  letterSpacing: '1px',
                  whiteSpace: 'nowrap',
                  maxWidth: '100%',
                  overflow: 'hidden'
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.background = 'rgba(97, 218, 251, 0.2)';
                  e.currentTarget.style.transform = 'translateY(-2px)';
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.background = 'rgba(97, 218, 251, 0.1)';
                  e.currentTarget.style.transform = 'translateY(0)';
                }}
              >
                View All Submissions on GitHub
                <span style={{ fontSize: windowWidth <= 480 ? '1rem' : windowWidth <= 768 ? '1.1rem' : '1.2rem' }}>→</span>
              </a>
            </div>
          </div>
        </section>

        <section className="price-chart-section">
          <div className="price-chart-card">
            <PriceChart tokenSymbol="XAH" />
          </div>
          <div className="price-chart-card">
            <PriceChart tokenSymbol="XRP" />
          </div>
          <div className="price-chart-card">
            <PriceChart tokenSymbol="EVR" />
          </div>
        </section>
      </div>
    </div>
  );
};

export default Home;