import React from 'react';
import ImageGallery from './ImageGallery';
import PriceChart from './PriceChart';
import Card from './Card';
import './Home.css';

const Home = () => {
  return (
    <div className="home">
      <section className="whats-new-section">
        <h2 className="section-title">What's new on Xahau?</h2>
        <div className="cards-grid">
          <Card
            image="/images/gov.png"
            title="Governance"
            description="Explore the Xahau governance system"
            link="/governance"
          />
          <Card
            image="/images/xahau_radio.png"
            title="Xahau Radio"
            description="Lo-Fi radio streaming with Xahau technology"
            link="https://xahauradio.com"
          />
          <Card
            image="/images/vpra.jpeg"
            title="VPRA"
            description="NFT game built on the Xahau blockchain"
            link="https://vpra.app/home"
            isExternal={true}
          />
        </div>
      </section>
      
      <section className="price-chart-section" style={{
        marginBottom: '100px' // Add significant margin to prevent footer overlap
      }}>
        <PriceChart />
      </section>
    </div>
  );
};

export default Home;