import { encodeAccountID } from 'ripple-address-codec';

// Function to convert hex to Uint8Array
const hexToUint8Array = (hex) => {
  return new Uint8Array(hex.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));
};

// Function to decode account ID
export const decodeAccountId = (accountId) => {
  try {
    // If it's already a valid r-address, return it
    if (accountId.startsWith('r') && accountId.length === 34) {
      return accountId;
    }
    // If it's a hex string, convert it to an r-address
    let byteArray = hexToUint8Array(accountId);
    
    // The encodeAccountID function expects a 20-byte input
    if (byteArray.length !== 20) {
      console.warn(`Unexpected account ID length: ${byteArray.length} bytes`);
      // If it's longer than 20 bytes, truncate it
      if (byteArray.length > 20) {
        byteArray = byteArray.slice(0, 20);
      } 
      // If it's shorter than 20 bytes, pad it with zeros
      else if (byteArray.length < 20) {
        const paddedArray = new Uint8Array(20);
        paddedArray.set(byteArray, 20 - byteArray.length);
        byteArray = paddedArray;
      }
    }
    
    return encodeAccountID(byteArray);
  } catch (error) {
    console.error('Error decoding account ID:', error);
    return 'Invalid Account ID';
  }
};

// Function to decode XFL
export const decodeXFL = (hex) => {
  // Convert hex string to byte array
  const bytes = hexToUint8Array(hex);
  
  // Read mantissa (first 7 bytes, little-endian)
  let mantissa = 0;
  for (let i = 0; i < 7; i++) {
    mantissa += bytes[i] * Math.pow(256, i);
  }
  
  // Read exponent (8th byte, signed)
  const exponent = bytes[7] & 0x7F;
  const sign = bytes[7] & 0x80 ? -1 : 1;
  
  // Calculate value
  const value = mantissa * Math.pow(10, sign * (exponent - 97));
  return value;
};