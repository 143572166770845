import React from 'react';
import { Link } from 'react-router-dom';
import './Card.css';

const Card = ({ 
  image, 
  title, 
  description, 
  link, 
  isExternal = false 
}) => {
  const CardWrapper = isExternal ? 'a' : Link;
  const cardProps = isExternal ? { 
    href: link, 
    target: "_blank", 
    rel: "noopener noreferrer" 
  } : { 
    to: link 
  };

  return (
    <CardWrapper {...cardProps} className="card">
      <div className="card-image">
        <img src={image} alt={title} />
      </div>
      <div className="card-content">
        <h3 className="card-title">{title}</h3>
        <p className="card-description">{description}</p>
      </div>
    </CardWrapper>
  );
};

export default Card; 